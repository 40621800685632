import { useEffect, useState } from 'react'

type UseScrollPositionType = {
  x: number
  y: number
}

type UseScrollPositionParam = {
  element?: Element | Window
  debounceDelay?: number
  onChange?: (position: UseScrollPositionType) => void
}

const useScrollPosition = ({
  element = window,
  onChange,
  debounceDelay = 300,
}: UseScrollPositionParam): UseScrollPositionType => {
  const [scrollPosition, setScrollPosition] = useState<UseScrollPositionType>({
    x: 0,
    y: 0,
  })

  const [debouncedValue, setDebouncedValue] = useState(scrollPosition)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(scrollPosition)
      onChange?.(scrollPosition)
    }, debounceDelay)

    return () => {
      clearTimeout(handler)
    }
  }, [scrollPosition, onChange, debounceDelay])

  useEffect(() => {
    const handleScroll = () => {
      const _scrollPosition =
        element instanceof Element
          ? {
              x: element.scrollTop,
              y: element.scrollLeft,
            }
          : {
              x: element.scrollX,
              y: element.scrollY,
            }

      setScrollPosition(_scrollPosition)
    }

    element.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [element])

  return debouncedValue
}

export default useScrollPosition
