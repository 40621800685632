import config from 'config'
import mixpanel from 'mixpanel-browser'

class MixpanelService {
  private initialized = false
  private userId = ''
  private readonly token: string | undefined

  constructor() {
    const { projectToken } = config.mixpanel
    this.token = projectToken
  }

  initialize() {
    if (this.initialized || !this.token) return

    try {
      mixpanel.init(this.token, {
        debug: process.env.NODE_ENV === 'development',
        persistence: 'localStorage',
      })

      this.initialized = true
    } catch (error) {
      console.error('Mixpanel initialization failed:', error)
    }
  }

  track(eventName: string, properties?: { [key: string]: any }) {
    if (this.initialized) {
      mixpanel.track(eventName, properties)
    }
  }

  isIdentified() {
    return Boolean(this.userId)
  }

  identify({ userId, name, roles }: { userId: string; name: string; roles: string[] }) {
    if (this.initialized) {
      mixpanel.identify(userId)
      mixpanel.people.set({
        name,
        roles,
      })
      this.userId = userId
    }
  }
}

export default new MixpanelService()
