import type { EntityId } from '../shared'

export enum PriorAuthorizationStatus {
  ManualStart = 'ManualStart',
  Pending = 'Pending',
  ProviderNotified = 'ProviderNotified',
  PayerNotified = 'PayerNotified',
  Approved = 'Approved',
  Denied = 'Denied',
  Error = 'Error',
  Cancelled = 'Cancelled',
  Inactive = 'Inactive',
  Complete = 'Complete',
}

export enum PriorAuthorizationOrigin {
  User = 'User',
  Copay = 'Copay',
}

export const ACTIONABLE_PRIOR_AUTHORIZATION_STATUS: string[] = [
  PriorAuthorizationStatus.Pending,
  PriorAuthorizationStatus.ManualStart,
  PriorAuthorizationStatus.ProviderNotified,
  PriorAuthorizationStatus.PayerNotified,
]

export const INACTIVE_PRIOR_AUTHORIZATION_STATUS = [
  PriorAuthorizationStatus.Cancelled,
  PriorAuthorizationStatus.Inactive,
  PriorAuthorizationStatus.Approved,
  PriorAuthorizationStatus.Denied,
]

export interface PriorAuthorizationActionButton {
  buttonLabel: string
  description: string
  url: string
  isPrimaryAction: boolean
  isRequiredAction: boolean
}

export interface PostClaimStatusServiceRequest {
  claimId: EntityId
  receivedEdi: string
  sentEdi: string
}

export interface PriorAuthorizationServiceRequest {
  prescriptionId: EntityId
  claimId: EntityId
  fillId: EntityId
  userId: EntityId
  locationId: EntityId
  customerId: EntityId
  patientId: EntityId
  orderId?: EntityId
  copayRequestId?: EntityId
  prescriberId: EntityId
  claimEdi: string
  rejectCode: string
  rejectMessage: string
  prescriberFax?: string
  medicationName: string
  force?: boolean
  origin?: string
}

export interface PriorAuthorizationServiceResponse {
  status: string
  priorAuthorizationId?: string
  error?: string
}

export interface PriorAuthorizationServicePutRequest {
  rxNumber: string
  npi: string
  cmmKey: string
}

export interface PriorAuthorizationServicePutStatusRequest {
  message: string
  status: string
  startedAt?: Date
  submittedAt?: Date
}

export interface PriorAuthorizationClaim {
  _id: EntityId
  createdAt: Date
  claimId: EntityId
  receivedEdi: string
  sentEdi: string
  sendToCMMDate?: Date
  updatedAt: Date
}

export interface PriorAuthorizationInput {
  actions: PriorAuthorizationActionButton[]
  claims: PriorAuthorizationClaim[]
  cmmKey?: string
  cmmStatus?: string
  customerId: EntityId
  initiatedBy: {
    claimId: EntityId
    copayRequestId?: EntityId
    fillId: EntityId
    orderId?: EntityId
    userId: EntityId
    origin?: PriorAuthorizationOrigin
  }
  isManual?: boolean
  locationId: EntityId
  medicationName: string
  message?: string
  ndc: string
  npi: string
  patientId: EntityId
  prescriberFax: string
  prescriberId: EntityId
  prescriptionId: EntityId
  rxNumber: number
  status: PriorAuthorizationStatus
  urgent?: boolean
  submittedAt?: Date
  startedAt?: Date
}

export const PRIOR_AUTHORIZATION_REJECT_CODES = [
  '70',
  '75',
  '76',
  'MR',
  'AG',
  'A5',
  'A6',
  '7X',
  '7Y',
  '9G',
  '608',
  '60',
  '80',
  '66',
  '64',
  'AJ',
  '61',
  '828',
]
