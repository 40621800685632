interface filterParams {
  isOtcMultiItems?: boolean
  isOtcOneItem?: boolean
  skus?: string[]
  otcMedicationNames?: string[]
}

export const getOtcFilter = ({ isOtcMultiItems, isOtcOneItem, skus, otcMedicationNames }: filterParams) => {
  if (isOtcMultiItems) {
    return {
      $and: [{ 'otcProducts.1': { $exists: true } }, { rxFillRequests: { $size: 0 } }],
    }
  }

  if (isOtcOneItem) {
    return {
      $and: [{ otcProducts: { $size: 1 } }, { rxFillRequests: { $size: 0 } }],
    }
  }

  if (skus) {
    return {
      $and: [{ 'otcProducts.sku': { $in: skus } }],
    }
  }

  if (otcMedicationNames) {
    return {
      $and: [{ 'otcProducts.name': { $in: otcMedicationNames } }],
    }
  }

  return {}
}

export default getOtcFilter
