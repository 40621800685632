import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_PRIOR_AUTHORIZATION_BY_STATUS_COUNT } from 'gql'
import type { GetFulfillmentPriorAuthorizationParams } from './navigation/useFulfillmentPriorAuthorizations'

export interface CountsByPriorAuthorizationStatus {
  manualStart: number
  pending: number
  providerNotified: number
  payerNotified: number
  all: number
}
export interface GetPriorAuthorizationsByStatusCountResult {
  countByStatus: CountsByPriorAuthorizationStatus
}

const usePriorAuthorizationCountByStatus = ({
  params,
}: {
  params: GetFulfillmentPriorAuthorizationParams
}): QueryResult<GetPriorAuthorizationsByStatusCountResult, GetFulfillmentPriorAuthorizationParams> => {
  return useQuery<GetPriorAuthorizationsByStatusCountResult, GetFulfillmentPriorAuthorizationParams>(
    GET_PRIOR_AUTHORIZATION_BY_STATUS_COUNT,
    {
      fetchPolicy: 'network-only',
      variables: params,
    },
  )
}

export default usePriorAuthorizationCountByStatus
