import type { QueryResult } from '@truepill/tpos-react-router'
import { useLazyQuery, useQuery } from '@truepill/tpos-react-router'
import { LIST_FULFILLMENT_PRIOR_AUTHORIZATIONS } from 'gql'
import type { PriorAuthorization } from 'types'

export interface GetFulfillmentPriorAuthorizationParams {
  isOrder?: boolean
  isCopay?: boolean
  locationId?: string
  customerIds?: string[]
  statuses?: string[]
  patientId?: string
  rxNumber?: number
  stringSearch?: string
  pageNumber?: number
  pageSize?: number
  sort?: number
}

export interface GetFulfillmentPriorAuthorizationsResult {
  getFulfillmentPriorAuthorizations: {
    currentPage: number
    totalRecords: number
    priorAuthorizations: PriorAuthorization[]
  }
}

export const useLazyFulfillmentPriorAuthorizations = () => {
  return useLazyQuery<GetFulfillmentPriorAuthorizationsResult, GetFulfillmentPriorAuthorizationParams>(
    LIST_FULFILLMENT_PRIOR_AUTHORIZATIONS,
    {
      fetchPolicy: 'network-only',
    },
  )
}

const useFulfillmentPriorAuthorizations = (
  priorAuthorizationQueryVariables: GetFulfillmentPriorAuthorizationParams,
): QueryResult<GetFulfillmentPriorAuthorizationsResult, GetFulfillmentPriorAuthorizationParams> => {
  // ensure some filters are set before executing the query when statuses is empty (all PAs are searched)
  let skip = false
  if (!priorAuthorizationQueryVariables.statuses?.length) {
    const { statuses, ...rest } = priorAuthorizationQueryVariables
    if (!Object.keys(rest).length) {
      skip = true
    }
  }

  return useQuery<GetFulfillmentPriorAuthorizationsResult, GetFulfillmentPriorAuthorizationParams>(
    LIST_FULFILLMENT_PRIOR_AUTHORIZATIONS,
    {
      skip,
      variables: priorAuthorizationQueryVariables,
      fetchPolicy: 'network-only',
    },
  )
}

export default useFulfillmentPriorAuthorizations
