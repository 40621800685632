type ApplicationIdentifier = {
  length: number // Length of the AI
  variableLength?: boolean // Set to true if the AI has variable length
}

// For list of Application Identifiers:
// https://www.gs1.org/standards/barcodes/application-identifiers/01?lang=en
const aiDefinitions: { [key: string]: ApplicationIdentifier } = {
  '00': { length: 18 },
  '01': { length: 14 }, // GTIN
  '10': { length: 20, variableLength: true }, // Batch or lot number
  '17': { length: 6 }, // Expiry date
  '21': { length: 20, variableLength: true }, // Serial number
  '91': { length: 100, variableLength: true }, // Company internal information
  '92': { length: 100, variableLength: true }, // Company internal information
  '93': { length: 100, variableLength: true }, // Company internal information
  '94': { length: 100, variableLength: true }, // Company internal information
  '95': { length: 100, variableLength: true }, // Company internal information
  '96': { length: 100, variableLength: true }, // Company internal information
  '97': { length: 100, variableLength: true }, // Company internal information
  '98': { length: 100, variableLength: true }, // Company internal information
  '99': { length: 100, variableLength: true }, // Company internal information
}

export function parseGS1String(gs1String: string): Record<string, string> {
  const result: Record<string, string> = {}
  let index = 0

  while (index < gs1String.length) {
    let aiFound = false

    // Try to match known AIs
    for (const ai in aiDefinitions) {
      if (gs1String.startsWith(ai, index)) {
        const { length, variableLength } = aiDefinitions[ai]
        let valueEnd = index + ai.length + length

        if (variableLength) {
          // For variable length AIs, find the next GS or the end of the string
          const gsIndex = gs1String.indexOf(String.fromCharCode(29), index + ai.length)
          valueEnd = gsIndex !== -1 ? gsIndex : gs1String.length
        }

        const value = gs1String.substring(index + ai.length, valueEnd)
        result[ai] = value
        index = valueEnd + (variableLength ? 1 : 0) // Skip the GS if variable length
        aiFound = true
        break
      }
    }

    if (!aiFound) {
      index++
    }
  }

  return result
}
