import { useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as CircleCheckIcon } from 'assets/icons/circlecheck.svg'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { GET_NDC_PACKAGE } from 'components/ReviewPrescription'
import RXScanImage from 'components/RXScanImage'
import {
  ListRowLabel,
  ListRowValue,
  SmallPaddingBlock,
  RaisedAnchor,
  RXImageCell,
  ImageFilledHeader,
  RXListRowSingle,
  StyledRXImageCell,
} from 'components/RXTable'
import Lozenge from 'components/Tiles/Lozenge'
import dayjs from 'dayjs'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import moment from 'moment'
import { useFlag } from 'providers/LaunchDarklyProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, confirmColor, errorRed, primaryBackgroundColor, warningYellow } from 'styles/styleVariables'
import type { Fill, NdcFullInfoPackage, Prescription } from 'types'
import { calculateFillsRemaining, checkIfProvided, getNdcText, getPackageMeasureDescription } from 'utils'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'
import { twoDecimalOrLess } from 'utils/numberFormatter'
import { hasOpenFills } from 'utils/Prescription'

type ReviewPrescriptionProps = {
  prescription: Prescription
  fill: Fill
}

const ReviewPrescription = ({ prescription, fill }: ReviewPrescriptionProps): JSX.Element => {
  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hypenhateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)
  const [rxImageIsPic, setRxImageIsPic] = useRxImageIsPic()

  const { data } = useQuery(GET_NDC_PACKAGE, {
    variables: { ndcs: [prescription.ndc] },
  })

  const ndcPackage = data?.getNdcPackage?.[0] as NdcFullInfoPackage
  const ndcText = getNdcText(ndcPackage, tempDisplayPackSizeWithNdc, hypenhateNdc)
  useHotKey('x', HotKeyLevel.normal, () => setRxImageIsPic(!rxImageIsPic))

  let fillTimeLozenge, lastFillDate

  if (prescription?.lastEquivalentDrugDispensedInfo) {
    const {
      nextFillInfo: { daysLeft, isOkToFill },
      dispensed: { dispensedAt },
    } = prescription.lastEquivalentDrugDispensedInfo
    lastFillDate = dayjs(dispensedAt).format('YYYY-MM-DD')

    const isPastDue = daysLeft <= 0

    const pastDueLozenge = (
      <Lozenge backgroundColor={warningYellow}>
        <StyledCircleCheckIcon fill={bodyPrimaryColor} />
        PAST DUE
      </Lozenge>
    )

    const dueInLozenge = (
      <Lozenge backgroundColor={isOkToFill ? confirmColor : errorRed}>
        {isOkToFill ? (
          <StyledCircleCheckIcon fill={primaryBackgroundColor} />
        ) : (
          <StyledDangerIcon fill={primaryBackgroundColor} />
        )}
        DUE IN: {daysLeft} DAY{daysLeft !== 1 && 'S'}
      </Lozenge>
    )

    fillTimeLozenge = isPastDue ? pastDueLozenge : dueInLozenge
  }

  return (
    <>
      <TitleRow>
        <SmallPaddingBlock />
        <RXImageInsuranceFilledHeader>
          <RaisedAnchor id={`ReviewPrescription`} />
          Prescription
        </RXImageInsuranceFilledHeader>
      </TitleRow>
      {rxImageIsPic && (
        <StyledRXImageCell noColorBackground closeOffTopBorder>
          <RXScanImage prescription={prescription} />
        </StyledRXImageCell>
      )}
      <RXListRowSingle>
        <RXImageCell closeOffTopBorder>
          <ListRowLabel>Medication:</ListRowLabel>
          <ListRowValue>
            {getDisplayedDrugName(
              prescription.name,
              prescription.strength?.value,
              prescription.strength?.unit,
              prescription.strength?.form,
            )}
          </ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>NDC:</ListRowLabel>
          <ListRowValue>{ndcText}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Mfg:</ListRowLabel>
          <ListRowValue>{fill.dispensed.manufacturer}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Qty Remaining:</ListRowLabel>
          <ListRowValue>
            {twoDecimalOrLess(prescription.quantityRemaining)}{' '}
            {getPackageMeasureDescription(fill.dispensed.packageSizeUnitOfMeasure)}
          </ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Qty:</ListRowLabel>
          <ListRowValue>
            {twoDecimalOrLess(prescription.quantity as number)}{' '}
            {getPackageMeasureDescription(fill.dispensed.packageSizeUnitOfMeasure)}
          </ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>DS:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.daysSupply)}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Fills Written:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.numberOfRefills)}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Fills Remaining:</ListRowLabel>
          <ListRowValue>
            {hasOpenFills(prescription) ? (
              <span>{`${calculateFillsRemaining(prescription)} (including current fill)`}</span>
            ) : (
              calculateFillsRemaining(prescription)
            )}
          </ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Refills:</ListRowLabel>
          <ListRowValue>{prescription.numberOfRefills}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>DAW:</ListRowLabel>
          <ListRowValue>{prescription.daw}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Directions:</ListRowLabel>
          <ListRowValue>{prescription.directions}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Written:</ListRowLabel>
          <ListRowValue>{moment(prescription.writtenDate).format('MM/DD/YYYY')}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Last Fill:</ListRowLabel>
          <ListRowValue>
            {lastFillDate ? (
              <>
                {lastFillDate} {fillTimeLozenge}
              </>
            ) : (
              'Not applicable'
            )}
          </ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Fill:</ListRowLabel>
          <ListRowValue>{moment(fill.dispensedAt).format('MM/DD/YYYY')}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Expiration:</ListRowLabel>
          <ListRowValue>{moment(prescription.writtenDate).add(1, 'year').format('MM/DD/YYYY')}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Effective:</ListRowLabel>
          <ListRowValue>
            {prescription.effectiveDate
              ? moment(prescription.effectiveDate).add(1, 'year').format('MM/DD/YYYY')
              : 'None provided'}
          </ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Notes:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.note)}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Triplicate:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.triplicate)}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell closeOffBottomBorder>
          <ListRowLabel>ICD 10:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.icd10s?.map(x => x.code).join(','))}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
    </>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  margin-right: 0.25rem;
`

const StyledDangerIcon = styled(DangerIcon)`
  margin-right: 0.25rem;
`

const TitleRow = styled.div``

export default ReviewPrescription
