import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Checkbox, Select } from '@truepill/react-capsule'
import { LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as Shelves } from 'assets/icons/shelves.svg'
import IconWrapper from 'components/IconWrapper'
import { GET_SHELF_LOCATIONS } from 'gql'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { capitalize } from 'utils'

type ShelfLocationsSelectProps = {
  label: string
  isVisible?: boolean
  variant?: 'small' | 'large'
}

const defaultOption = {
  name: 'All shelf locations',
  value: 'All shelf locations',
}

const ShelfLocationsSelect = (props: ShelfLocationsSelectProps): JSX.Element => {
  const [allOptionsUnchecked, setAllOptionsdUnchecked] = useState(false)
  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false

  const { label, variant = 'large', isVisible = false } = props
  const { currentLocation, routeToMergedQuery } = usePlusClient()

  const locationId = currentLocation?.queryMap?.locationId
  const selectedOptions = currentLocation?.queryMap?.shelfLocations || []

  const { data: getShelfLocationsData } = useQuery(GET_SHELF_LOCATIONS, {
    variables: { locationId },
    skip: !isVisible,
  })

  const shelfLocationsOptions: { name: string; value: string }[] = Object.values(
    (getShelfLocationsData?.getShelfLocations?.shelfLocations as string[]) || [],
  ).map(value => ({ name: value, value }))

  const options = [defaultOption, ...shelfLocationsOptions]

  const value = selectedOptions.length === 0 ? defaultOption.value : selectedOptions.join(',')

  return (
    <Select
      variant={variant}
      label={label && capitalize(label)}
      css={{
        ul: { maxHeight: '21rem' },
        ...(displayDashboardDesignV2 && variant === 'small' && { width: '235px' }),
      }}
      value={value}
      onChange={e => {
        // Do not close the dropdown everytime a check is selected
        e.preventDefault()
      }}
      options={options}
      placeholder="Select Shelf Locations"
      selectedKey="name"
      startAdornment={() =>
        displayDashboardDesignV2 ? (
          <IconWrapper>
            <Shelves />
          </IconWrapper>
        ) : (
          <></>
        )
      }
      optionComponent={({ option }) => (
        <>
          <Checkbox
            label={option?.name}
            checked={(selectedOptions.length === 0 && !allOptionsUnchecked) || selectedOptions.includes(option?.value)}
            onCheckedChange={checked => {
              const isDefaultOption = option?.value === defaultOption.value

              if (isDefaultOption) {
                setAllOptionsdUnchecked(!checked)
                routeToMergedQuery({ shelfLocations: undefined })
                return
              }

              if (checked) {
                const allOptionsSelected = selectedOptions.length + 1 === shelfLocationsOptions.length
                routeToMergedQuery({
                  shelfLocations: allOptionsSelected ? undefined : [...selectedOptions, option?.value],
                })
              } else {
                const newSelectedOptions =
                  selectedOptions.length === 0
                    ? shelfLocationsOptions.filter(opt => opt.value !== option?.value).map(opt => opt.value)
                    : selectedOptions.filter((value: string) => value !== option?.value)

                routeToMergedQuery({ shelfLocations: newSelectedOptions })
              }
            }}
          />
        </>
      )}
    />
  )
}

export default ShelfLocationsSelect
