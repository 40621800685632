import type { InsurancePatientRelationship } from '@truepill/tpos-types'
import { FieldCodes } from '@truepill/tpos-types'

// create an object from the enum without the reverse keys
const insuranceRelationship = Object.fromEntries(
  Object.entries(FieldCodes.PatientRelationship).filter(([, val]) => typeof val === 'number'),
)

const mapCoreInsuranceRelationship = <T extends InsurancePatientRelationship | null | undefined>(
  relationship: string | null,
  defaultValue: T,
): InsurancePatientRelationship | T => {
  let stringValue = relationship ?? ''

  // value already correct?
  if (stringValue in insuranceRelationship) {
    return stringValue as InsurancePatientRelationship
  }

  // should be an int so try to parse it as an int
  const intValue = parseInt(stringValue, 10)

  if (!isNaN(intValue)) {
    const found = Object.entries(insuranceRelationship).find(([, code]) => code === intValue)

    if (found) {
      return found[0] as InsurancePatientRelationship
    }
  }

  // try lower case with whitespace stripped
  stringValue = stringValue.toLowerCase().split(/\s/).join('')
  const match = Object.keys(insuranceRelationship).find(relationship => relationship.toLowerCase() === stringValue)

  if (match) {
    return match as InsurancePatientRelationship
  }

  // not sure what the value is supposed to be so fall back to the default value
  return defaultValue
}
export default mapCoreInsuranceRelationship
