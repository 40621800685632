import type { PrescriptionService } from '@truepill/openapi-sdk'

export interface ExternalLocation {
  id: string
  npi: string
  name?: string
  address: {
    street1: string
    street2?: string
    city: string
    state: string
    zip: string
    phone?: string
    fax?: string
  }
}

export interface ExternalTransfer {
  id: string
  externalLocation: ExternalLocation
  status: PrescriptionService.ExternalTransferStatusV1
  createdAt: string
  updatedAt: string
  locked: boolean
  lockedAt?: string
  lockedBy?: string
  prescription: {
    corePrescriptionId: number
    corePrescriptionToken: string
    medicationName: string
    rxNumber: string
    verified?: boolean
  }
  patient?: {
    firstName: string
    lastName: string
  }
  customer?: {
    id: string
    name: string
    legacyId: number
  }
}

export enum ExternalTransferStatusDisplay {
  PV1 = 'Prescription Verification',
  PENDING = 'Ready To Transfer',
  FAILED = 'Transfer Rejected',
  AUTOMATION_FAILED = 'Automated Fax Failed',
  TRANSFERRED = 'TRANSFERRED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  AUTOMATION_PENDING = 'Automated Fax Pending',
}
