import { useEffect, useState } from 'react'
import { Chip, Tooltip } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import { ClaimDataPaidStatus, UserRoles, LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as CircleCheckIcon } from 'assets/icons/circlecheck.svg'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as DsFlag } from 'assets/icons/ds-flag.svg'
import { ReactComponent as SigFlag } from 'assets/icons/sig-flag.svg'
import CriticalMedicalNoteWarning from 'components/CriticalMedicalNoteWarning'
import DatePicker from 'components/DatePicker'
import HotKeyToolTip from 'components/HotKeyToolTip'
import Icd10Search from 'components/Icd10Search'
import { NumericInput } from 'components/NumericInput'
import PrescriptionQtyMissmatch from 'components/PrescriptionQtyMissmatch'
import { EditMode, NormalMode } from 'components/RXPageStructure'
import {
  LeftSubTitle,
  ListRowLabel,
  ListRowValue,
  PaddingBlock,
  RaisedAnchor,
  RightFilledHeader,
  RightSubTitle,
  RXCenterAndRightCell,
  RXCenterCell,
  RXCenterCellHeader,
  RXImageCell,
  RXListRow,
  RXRightCell,
  RXTitleRow,
  StyledSelect,
  StyledTextInput,
  TwoColumnFilledHeader,
  ImageFilledHeader,
  StyledRXImageCell,
  DiffTooltip,
} from 'components/RXTable'
import Lozenge from 'components/Tiles/Lozenge'
import dayjs from 'dayjs'
import { gql } from 'gql/generated'
import { Box, TextArea } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useAnalytics from 'hooks/useAnalytics'
import { useFormData } from 'hooks/useFormData'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { isNil } from 'ramda'
import { PV1Events } from 'services/analytics/events/pv1Events'
import styled, { css } from 'styled-components'
import {
  alertRed,
  bodyPrimaryColor,
  borderColor,
  confirmColor,
  errorRed,
  primaryBackgroundColor,
  warningYellow,
} from 'styles/styleVariables'
import type { Prescription, QueueItem, Fill, NdcFullInfoPackage } from 'types'
import { DAW, PrescriptionOrigin } from 'types'
import {
  labelForDAW,
  checkIfProvided,
  calculateFillsRemaining,
  getPackageMeasureDescription,
  getRxImagePrescription,
  directionsLengthDetails,
  getRxImagePrescriptionManualEntry,
  isCopayRequest,
  getItemFill,
  getNdcText,
} from 'utils'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'
import { getFormattedInventoryGroupName } from 'utils/inventoryGroup'
import { twoDecimalOrLess } from 'utils/numberFormatter'
import { hasOpenFills } from 'utils/Prescription'
import { buildReviewPrescriptionSectionValues } from 'utils/rxFields'
import { adjustDateToTimezone } from '../utils/dates'
import MedicationCard from './MedicationCard'
import NdcPackageSearch from './NdcPackageSearch'
import RXScanImage from './RXScanImage'

// TODO: move this fragment definition to their respective gql
// required for the following declaration
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NdcPackage_NdcPackageFragment = gql(`
  fragment NdcPackage_NdcPackageFragment on NdcFullInfoPackage {
      ndc
      packageSize
      packageSizeUnitOfMeasure
      packageQuantity
      isUnbreakable
      hasInnerPackSibling
  }
`)

export const GET_NDC_PACKAGE = gql(`
  query getNdcPackage($ndcs: [String]!) {
    getNdcPackage(ndcs: $ndcs) {
      ...NdcPackage_NdcPackageFragment
    }
  }
`)

type MakeReviewPrescriptionHeaderProps = { prescription: Prescription; claimsView: boolean; fill?: Fill }
// TODO move to own component
const MakeReviewPrescriptionHeader = ({
  prescription,
  claimsView,
  fill,
}: MakeReviewPrescriptionHeaderProps): JSX.Element => {
  const [rxImageIsPic, setRxImageIsPic] = useRxImageIsPic()
  const { trackPV1Event } = useAnalytics()
  useHotKey('x', HotKeyLevel.normal, () => {
    trackPV1Event(PV1Events.PV1_TOGGLE_RX_IMAGE.key, {
      fillId: fill?._id,
    })
    setRxImageIsPic(!rxImageIsPic)
  })
  if (claimsView) {
    if (!prescription?.escript) {
      setRxImageIsPic(true)
    }
    return (
      <InsuranceTitleRow>
        <PaddingBlock />
        <RaisedAnchor id={`ReviewPrescription`} />
        <RXImageInsuranceFilledHeader>
          Prescription
          <HotKeyToolTip label={'3'} position={'right'} offsetLeft={6} offsetTop={-1} />
        </RXImageInsuranceFilledHeader>
        <RXListRow noStripe>
          {rxImageIsPic && (
            <StyledRXImageCell noColorBackground closeOffTopBorder>
              <RXImageBox>
                <RXScanImage prescription={prescription} inline={true} />
              </RXImageBox>
            </StyledRXImageCell>
          )}
          <RXImageCell closeOffTopBorder />
          <RXCenterCell>
            <LeftSubTitle>Prescribed medication</LeftSubTitle>
          </RXCenterCell>
          {fill ? (
            <RXRightCell>
              <RightSubTitle>Dispensed medication</RightSubTitle>
            </RXRightCell>
          ) : (
            <></>
          )}
        </RXListRow>
      </InsuranceTitleRow>
    )
  }

  return (
    <>
      <RXTitleRow data-testid="headers">
        <RXImageCell></RXImageCell>
        <EditMode>
          <RXCenterCellHeader>
            <PaddingBlock />
            <TwoColumnFilledHeader>
              <RaisedAnchor id={`ReviewPrescription`} />
              Prescription
              <HotKeyToolTip label={'3'} position={'right'} offsetLeft={1} offsetTop={0} />
            </TwoColumnFilledHeader>
          </RXCenterCellHeader>
        </EditMode>
        <NormalMode>
          <RXCenterAndRightCell>
            <PaddingBlock />
            <RightFilledHeader>
              <RaisedAnchor id={`ReviewPrescription`} />
              Review prescription
              <HotKeyToolTip label={'3'} position={'right'} offsetLeft={1} offsetTop={0} />
            </RightFilledHeader>
          </RXCenterAndRightCell>
        </NormalMode>
      </RXTitleRow>
      <NormalMode>
        <RXListRow data-testid="spacer-for-shading" />
        <RXListRow data-testid="subheaders" noStripe>
          <RXImageCell></RXImageCell>
          <RXCenterCell>
            <LeftSubTitle>Prescribed medication</LeftSubTitle>
          </RXCenterCell>
          {fill ? (
            <RXRightCell>
              <RightSubTitle>Dispensed medication</RightSubTitle>
            </RXRightCell>
          ) : (
            <></>
          )}
        </RXListRow>
      </NormalMode>
    </>
  )
}

type ReviewPrescriptionProps = {
  prescription: Prescription
  item?: QueueItem
  fill?: Fill
  claimsView?: boolean
  setCanConfirm?: (canConfirm: boolean) => void
  highlightedDispensedFields?: Map<string, boolean>
  highlightedPrescribedFields?: Map<string, boolean>
  hideRefillsWrittenField?: boolean
}

const ReviewPrescription = ({
  item,
  claimsView = false,
  setCanConfirm,
  prescription,
  fill,
  hideRefillsWrittenField = false,
  highlightedPrescribedFields = new Map<string, boolean>(),
  highlightedDispensedFields = new Map<string, boolean>(),
}: ReviewPrescriptionProps): JSX.Element => {
  const shouldApplyInterfaceImprovements = useFlag(LaunchDarkly.FeatureFlags.PV1_INTERFACE_CONSISTENCY)

  const { routeToHash, tokenContext } = usePlusClient()
  const [, setToggleUneditable] = useState<boolean>(false)

  const { getLocationById } = useTPCacheContext()
  const location = getLocationById(prescription.locationId)
  const timezone = location?.timezone

  const showErrorToast = useErrorToast(true)
  const { trackPV1Event } = useAnalytics()

  const escript = prescription?.escript
  const directTransferPrescription = prescription?.directTransfer?.prescription

  let rxImagePrescription

  if (prescription.origin === PrescriptionOrigin.TELEPHONE) {
    rxImagePrescription = getRxImagePrescriptionManualEntry(prescription)
  } else {
    rxImagePrescription = getRxImagePrescription(escript, directTransferPrescription)
  }

  const [currentFill, setCurrentFill] = useState<string>(fill?._id ?? '')

  useEffect(() => {
    if (fill?.claims.length && ClaimDataPaidStatus.includes(fill?.claims[fill?.claims.length - 1].status ?? '')) {
      setToggleUneditable(false)
    }
  }, [fill])

  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()

  const prescriptionFormData = formData.prescription as Prescription

  useEffect(() => {
    if (!prescriptionFormData?._id) {
      updateFormData({ prescription: { $set: prescription } })
    }
  }, [prescription, prescriptionFormData, updateFormData])

  useHotKey('3', HotKeyLevel.normal, () => {
    routeToHash(`ReviewPrescription`)
  })

  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hyphenateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  const { data } = useQuery(GET_NDC_PACKAGE, {
    // fallback to prevent passing undefined to graphql
    variables: { ndcs: [prescription.ndc, fill?.dispensed.ndc || prescription.ndc] },
  })

  useEffect(() => {
    if (currentFill !== fill?._id) {
      setCurrentFill(fill?._id ?? '')
    }
  }, [currentFill, fill?._id, fill?.dispensed?.manufacturer, fill?.dispensed.ndc])

  if (!prescription || !prescriptionFormData._id) {
    return <></>
  }

  const ndcPackages = data?.getNdcPackage as NdcFullInfoPackage[]
  const prescribedNdcPackage = ndcPackages?.find(ndcPackage => ndcPackage?.ndc === prescription.ndc)
  const dispensedNdcPackage = ndcPackages?.find(ndcPackage => ndcPackage?.ndc === fill?.dispensed?.ndc)

  const ndcText = getNdcText(prescribedNdcPackage, tempDisplayPackSizeWithNdc, hyphenateNdc)
  const dispensedNdcText = getNdcText(dispensedNdcPackage, tempDisplayPackSizeWithNdc, hyphenateNdc)

  let fillTimeLozenge, lastFillDate

  if (prescription?.lastEquivalentDrugDispensedInfo) {
    const {
      nextFillInfo: { daysLeft, isOkToFill },
      dispensed: { dispensedAt },
    } = prescription.lastEquivalentDrugDispensedInfo
    lastFillDate = dayjs(dispensedAt).format('YYYY-MM-DD')

    const isPastDue = daysLeft <= 0

    const pastDueLozenge = (
      <Lozenge backgroundColor={warningYellow}>
        <StyledCircleCheckIcon fill={bodyPrimaryColor} />
        PAST DUE
      </Lozenge>
    )

    const dueInLozenge = (
      <Lozenge backgroundColor={isOkToFill ? confirmColor : errorRed}>
        {isOkToFill ? (
          <StyledCircleCheckIcon fill={primaryBackgroundColor} />
        ) : (
          <StyledDangerIcon fill={primaryBackgroundColor} />
        )}
        DUE IN: {daysLeft} DAY{daysLeft !== 1 && 'S'}
      </Lozenge>
    )

    fillTimeLozenge = isPastDue ? pastDueLozenge : dueInLozenge
  }

  const { hasRole, isAdmin, isPharmacist } = tokenContext

  const canUserEdit =
    (hasRole &&
      hasRole([
        UserRoles.Pharmacist,
        UserRoles.LeadPharmacist,
        UserRoles.CustomerSupport,
        UserRoles.LeadCustomerSupport,
        UserRoles.Technician,
      ])) ||
    isAdmin()

  const inventoryGroup = fill ? getFormattedInventoryGroupName(fill) : ''

  const fillDirectionsDetails = directionsLengthDetails(!!fill?.genericFor, fill?.dispensed.directions)
  const isQuantityEditable =
    (canUserEdit && fill?.fillNumber === 0) || prescription.fills.length === 0 || isAdmin() || isPharmacist()

  let quantity
  if (!!item && !!fill?._id) {
    quantity = isCopayRequest(item)
      ? getItemFill(item, fill?._id)?.dispensed?.quantity
      : getItemFill(item, fill?._id)?.quantity
  }

  const prescribedDrugName = prescriptionFormData.name
    ? getDisplayedDrugName(
        prescriptionFormData.name,
        prescriptionFormData.strength?.value,
        prescriptionFormData.strength?.unit,
        prescriptionFormData.strength?.form,
      )
    : undefined

  const dispensedDrugName = getDisplayedDrugName(
    fill?.dispensed.name ?? '',
    fill?.dispensed.strength?.value ?? '',
    fill?.dispensed.strength?.unit ?? '',
    fill?.dispensed.strength?.form ?? '',
  )

  const { rxPrescriptionData, reviewPrescriptionData } = buildReviewPrescriptionSectionValues(
    rxImagePrescription,
    ndcText,
    dispensedNdcText,
    prescribedDrugName,
    prescription,
    prescriptionFormData,
    location,
    shouldApplyInterfaceImprovements,
  )

  return (
    <Box id="ReviewPrescriptionBox" direction="column" data-testid="ReviewPrescription">
      <MakeReviewPrescriptionHeader claimsView={claimsView} prescription={prescription} fill={fill} />
      <RXListRow data-test-row="medication">
        <RXImageCell>
          <ListRowLabel>Medication:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.medication.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('name') ? 'highlighted' : ''}>
          <ListRowLabel>Medication:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.medication.highlightValue ? alertRed : bodyPrimaryColor}>
            <EditMode>
              {canUserEdit ? (
                prescribedDrugName ? (
                  <MedicationCard
                    ndc={prescriptionFormData.ndc}
                    hideFields={['Manufacturer', 'NDC']}
                    closeCallback={() => {
                      updateFormData({
                        prescription: {
                          ndc: { $set: undefined },
                          name: { $set: undefined },
                        },
                      })
                    }}
                  />
                ) : (
                  <NdcPackageSearchWrapper>
                    <NdcPackageSearch
                      withCapsule
                      groupedView
                      state={'default'}
                      showNdc={false}
                      value={prescribedDrugName}
                      onSelect={medication => {
                        updateFormData({
                          prescription: {
                            ndc: { $set: medication.ndc },
                            name: { $set: medication.name },
                          },
                        })
                      }}
                    />
                  </NdcPackageSearchWrapper>
                )
              ) : (
                <>{prescribedDrugName}</>
              )}
            </EditMode>
            <NormalMode>
              {reviewPrescriptionData.medication.highlightValue && (
                <DiffTooltip message={reviewPrescriptionData.medication.message} />
              )}
              <DrugNameWithWarning>
                {reviewPrescriptionData.medication.value}
                <CriticalMedicalNoteWarning ndc={prescriptionFormData.ndc} />
              </DrugNameWithWarning>
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>
        {fill ? (
          <NormalMode>
            <RXRightCell
              data-testid="dispensedMedicationMed"
              style={{ flexFlow: 'column', justifyContent: 'center' }}
              className={highlightedDispensedFields.get('name') ? 'highlighted' : ''}
            >
              <span>{dispensedDrugName}</span>
              <span>{fill.dispensed.alternateLabelName}</span>
              <CriticalMedicalNoteWarning style={{ marginTop: '0.25rem' }} ndc={fill.dispensed.ndc} />
            </RXRightCell>
          </NormalMode>
        ) : (
          <></>
        )}
      </RXListRow>

      <RXListRow data-test-row="ndc">
        <RXImageCell>
          <ListRowLabel>NDC:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.ndc.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('ndc') ? 'highlighted' : ''}>
          <ListRowLabel data-testid="ndc">NDC:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.ndc.highlightValue ? alertRed : bodyPrimaryColor}>
            {reviewPrescriptionData.ndc.highlightValue && <DiffTooltip message={reviewPrescriptionData.ndc.message} />}
            {ndcText}
          </ListRowValue>
        </RXCenterCell>
        {fill ? (
          <NormalMode>
            <RXRightCell
              data-testid="dispensedMedicationNDC"
              className={highlightedDispensedFields.get('ndc') ? 'highlighted' : ''}
            >
              {reviewPrescriptionData.ndc.value}
              {inventoryGroup}
            </RXRightCell>
          </NormalMode>
        ) : (
          <></>
        )}
      </RXListRow>

      <NormalMode>
        <RXListRow data-test-row="manufacturer">
          <RXImageCell>
            <ListRowLabel />
            <ListRowValue />
          </RXImageCell>
          <RXCenterCell>
            <ListRowLabel>Mfg:</ListRowLabel>
            <ListRowValue />
          </RXCenterCell>
          {fill ? (
            <RXRightCell
              data-testid="dispensedMedicationMfg"
              className={highlightedDispensedFields.get('manufacturer') ? 'highlighted' : ''}
            >
              {checkIfProvided(fill?.dispensed?.manufacturer)}
            </RXRightCell>
          ) : (
            <></>
          )}
        </RXListRow>
      </NormalMode>

      <RXListRow data-test-row="remaining">
        <RXImageCell>
          <ListRowLabel></ListRowLabel>
          <ListRowValue></ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('quantityRemaining') ? 'highlighted' : ''}>
          <ListRowLabel>Qty Remaining:</ListRowLabel>
          <ListRowValue>
            {twoDecimalOrLess(prescriptionFormData.quantityRemaining)}{' '}
            {getPackageMeasureDescription(fill?.dispensed?.packageSizeUnitOfMeasure)}
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>
      <RXListRow data-test-row="quantity" error={(prescriptionFormData.quantity as number) <= 0}>
        <RXImageCell>
          <ListRowLabel>Qty:</ListRowLabel>
          <ListRowValue>
            {checkIfProvided(twoDecimalOrLess(rxImagePrescription?.quantity))}{' '}
            {getPackageMeasureDescription(fill?.dispensed?.packageSizeUnitOfMeasure)}
          </ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('quantity') ? 'highlighted' : ''}>
          <ListRowLabel>Qty:</ListRowLabel>
          <ListRowValue>
            <EditMode>
              {/*The prescription is editable when there are no fills yet*/}
              {isQuantityEditable ? (
                <PaddedSmallNumberInput
                  value={prescriptionFormData.quantity as number}
                  onChange={newNumber => {
                    if (!isNil(newNumber)) {
                      updateFormData({
                        prescription: {
                          quantity: { $set: newNumber as number },
                        },
                      })
                    }
                  }}
                />
              ) : (
                `${prescriptionFormData.quantity} ${getPackageMeasureDescription(
                  fill?.dispensed?.packageSizeUnitOfMeasure,
                )}`
              )}
            </EditMode>
            <NormalMode>
              {prescriptionFormData.quantity === '.' ? '.' : twoDecimalOrLess(prescriptionFormData.quantity)}{' '}
              {getPackageMeasureDescription(fill?.dispensed?.packageSizeUnitOfMeasure)}
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>

        {fill ? (
          <NormalMode>
            <RXRightCell className={highlightedDispensedFields.get('quantity') ? 'highlighted' : ''}>
              {fill?.dispensed.quantity} {getPackageMeasureDescription(fill?.dispensed?.packageSizeUnitOfMeasure)}
              {quantity !== prescription.quantity && <PrescriptionQtyMissmatch />}
            </RXRightCell>
          </NormalMode>
        ) : (
          <></>
        )}
      </RXListRow>

      <RXListRow data-test-row="days-supply">
        <RXImageCell>
          <ListRowLabel>DS:</ListRowLabel>
          <ListRowValue>{checkIfProvided(rxImagePrescription?.daysSupply)}</ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('daysSupply') ? 'highlighted' : ''}>
          <ListRowLabel>DS:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.ds.highlightValue ? alertRed : bodyPrimaryColor}>
            <NormalMode>
              {reviewPrescriptionData.ds.highlightValue && <DiffTooltip message={reviewPrescriptionData.ds.message} />}
              {reviewPrescriptionData.ds.value}
            </NormalMode>
            <EditMode>
              {canUserEdit ? (
                <PaddedSmallNumberInput
                  value={prescriptionFormData.daysSupply || rxImagePrescription?.daysSupply}
                  onChange={newNumber => {
                    if (!isNil(newNumber)) {
                      updateFormData({
                        prescription: {
                          daysSupply: { $set: newNumber },
                        },
                      })
                    }
                  }}
                />
              ) : (
                `${prescriptionFormData.daysSupply || rxImagePrescription?.daysSupply}`
              )}
            </EditMode>
          </ListRowValue>
        </RXCenterCell>
        {fill ? (
          <NormalMode>
            {fill?.dispensed.daysSupply === 0 ? (
              <DsFlag />
            ) : (
              <RXRightCell className={highlightedDispensedFields.get('daysSupply') ? 'highlighted' : ''}>
                {fill?.dispensed.daysSupply}
              </RXRightCell>
            )}
          </NormalMode>
        ) : (
          <></>
        )}
      </RXListRow>

      {!hideRefillsWrittenField && (
        <RXListRow
          data-test-row="refills"
          error={(prescription.numberOfRefills ?? 1) < 0 || (prescriptionFormData.numberOfRefills ?? 1) < 0}
        >
          <RXImageCell>
            <ListRowLabel>Refills Written:</ListRowLabel>
            <ListRowValue>{checkIfProvided(`${rxImagePrescription?.numberOfRefills}`)}</ListRowValue>
          </RXImageCell>
          <RXCenterCell className={highlightedPrescribedFields.get('numberOfRefills') ? 'highlighted' : ''}>
            <ListRowLabel>Fills Written:</ListRowLabel>
            <ListRowValue>
              <EditMode>
                {canUserEdit ? (
                  <PaddedSmallNumberInput
                    value={prescriptionFormData.numberOfRefills}
                    onChange={newNumber => {
                      if (!isNil(newNumber)) {
                        updateFormData({
                          prescription: {
                            numberOfRefills: { $set: newNumber },
                          },
                        })
                      }
                    }}
                  />
                ) : (
                  `${prescriptionFormData.numberOfRefills}`
                )}
              </EditMode>
              <NormalMode>{prescription.numberOfRefills}</NormalMode>
            </ListRowValue>
          </RXCenterCell>
          {fill ? (
            <NormalMode>
              <RXRightCell></RXRightCell>
            </NormalMode>
          ) : (
            <></>
          )}
        </RXListRow>
      )}

      <RXListRow data-test-row="fills-remaining">
        <RXImageCell>
          <ListRowLabel />
          <ListRowValue />
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('refillsRemaining') ? 'highlighted' : ''}>
          <ListRowLabel>Fills Remaining:</ListRowLabel>
          <ListRowValue>
            {hasOpenFills(prescription) ? (
              <span>{`${calculateFillsRemaining(prescription)} (including current fill)`}</span>
            ) : (
              calculateFillsRemaining(prescription)
            )}
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>

      <RXListRow data-test-row="daw">
        <RXImageCell>
          <ListRowLabel>DAW:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.daw.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('daw') ? 'highlighted' : ''}>
          <ListRowLabel>DAW:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.daw.highlightValue ? alertRed : bodyPrimaryColor}>
            <EditMode>
              {canUserEdit ? (
                <PaddedStyledSelect
                  multiple={false}
                  value={{
                    label: labelForDAW(prescriptionFormData.daw),
                    value: prescriptionFormData.daw,
                  }}
                  placeholder="Select DAW..."
                  options={DAW.map((label, dawNumber) => ({
                    label: `${dawNumber} - ${label}`,
                    value: dawNumber,
                  }))}
                  onChange={([option]) => {
                    updateFormData({
                      prescription: {
                        daw: { $set: option.value as number },
                      },
                    })
                  }}
                  disableClear
                />
              ) : (
                `${labelForDAW(prescriptionFormData.daw)}`
              )}
            </EditMode>
            <NormalMode>
              {reviewPrescriptionData.daw.highlightValue && (
                <DiffTooltip message={reviewPrescriptionData.daw.message} />
              )}
              {reviewPrescriptionData.daw.value}
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>
        {fill ? (
          <NormalMode>
            <RXRightCell />
          </NormalMode>
        ) : (
          <></>
        )}
      </RXListRow>

      <RXListRow data-test-row="directions">
        <RXImageCell>
          <ListRowLabel>Directions:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.directions.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('directions') ? 'highlighted' : ''}>
          <ListRowLabel>Directions:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.directions.highlightValue ? alertRed : bodyPrimaryColor}>
            <NormalMode>
              {reviewPrescriptionData.directions.highlightValue && (
                <DiffTooltip message={reviewPrescriptionData.directions.message} />
              )}
              {reviewPrescriptionData.directions.value}
            </NormalMode>
            <EditMode>
              {canUserEdit ? (
                <PaddedStyledTextInput
                  value={prescriptionFormData.directions}
                  onChange={e => {
                    trackPV1Event(PV1Events.PV1_EDIT_DIRECTIONS.key, {
                      fillId: fill?._id,
                    })
                    updateFormData({
                      prescription: {
                        directions: { $set: e.target.value },
                      },
                    })
                  }}
                />
              ) : (
                `${prescriptionFormData.directions}`
              )}
            </EditMode>
          </ListRowValue>
        </RXCenterCell>
        {fill ? (
          <NormalMode>
            {fill?.dispensed.directions !== '' ? (
              <RXRightCell className={highlightedDispensedFields.get('directions') ? 'highlighted' : ''}>
                {fill?.dispensed.directions}
                {fillDirectionsDetails.tooLong && (
                  <TooltipContainer>
                    <Tooltip variant="white" label="Directions may print on leaflet instead of Rx label">
                      <DangerChip state="error">
                        <DangerIcon fill={alertRed} />
                      </DangerChip>
                    </Tooltip>
                  </TooltipContainer>
                )}
              </RXRightCell>
            ) : (
              <SigFlag />
            )}
          </NormalMode>
        ) : (
          <></>
        )}
      </RXListRow>

      <RXListRow data-test-row="written-date">
        <RXImageCell>
          <ListRowLabel>Written:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.written.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('writtenDate') ? 'highlighted' : ''}>
          <ListRowLabel>Written:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.expiration.highlightValue ? alertRed : bodyPrimaryColor}>
            <EditMode>
              {canUserEdit ? (
                <DateCellPadding>
                  <DatePicker
                    date={new Date(adjustDateToTimezone(prescriptionFormData.writtenDate, timezone))}
                    onChange={(date?: Date) =>
                      updateFormData({
                        prescription: {
                          writtenDate: { $set: dayjs(date).format('YYYY-MM-DD') },
                        },
                      })
                    }
                  />
                </DateCellPadding>
              ) : (
                `${adjustDateToTimezone(prescriptionFormData.writtenDate, timezone)}`
              )}
            </EditMode>
            <NormalMode>
              {reviewPrescriptionData.written.highlightValue && (
                <DiffTooltip message={reviewPrescriptionData.written.message} />
              )}
              {reviewPrescriptionData.written.value}
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>

      <RXListRow data-test-row="last-fill-date">
        <RXImageCell>
          <ListRowLabel />
          <ListRowValue />
        </RXImageCell>
        <RXCenterCell>
          <ListRowLabel>Last fill:</ListRowLabel>
          <ListRowValue>
            {lastFillDate ? (
              <>
                {lastFillDate} {fillTimeLozenge}
              </>
            ) : (
              'Not applicable'
            )}
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>

      <RXListRow data-test-row="expiration-date">
        <RXImageCell>
          <ListRowLabel>Expiration:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.expiration.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell className={highlightedPrescribedFields.get('expirationDate') ? 'highlighted' : ''}>
          <ListRowLabel>Expiration:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.expiration.highlightValue ? alertRed : bodyPrimaryColor}>
            <EditMode>
              {canUserEdit ? (
                <DateCellPadding>
                  <DatePicker
                    date={new Date(adjustDateToTimezone(prescriptionFormData.expirationDate, timezone).valueOf())}
                    onChange={(date?: Date) => {
                      const diff = dayjs(date).diff(prescription.writtenDate, 'days', true)
                      if (diff > 366) {
                        showErrorToast('Expiration Date can not be set further than one year from Written Date')
                        return
                      }
                      updateFormData({
                        prescription: {
                          expirationDate: { $set: dayjs(date).format('YYYY-MM-DD') },
                        },
                      })
                    }}
                  />
                </DateCellPadding>
              ) : (
                `${adjustDateToTimezone(prescriptionFormData.expirationDate, timezone)}`
              )}
            </EditMode>
            <NormalMode>
              {reviewPrescriptionData.expiration.highlightValue && (
                <DiffTooltip message={reviewPrescriptionData.expiration.message} />
              )}
              {reviewPrescriptionData.expiration.value}
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>

      <RXListRow data-test-row="notes">
        <RXImageCell>
          <ListRowLabel>Notes:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.notes.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell>
          <ListRowLabel>Notes:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.notes.highlightValue ? alertRed : bodyPrimaryColor}>
            <EditMode>
              {canUserEdit ? (
                <StyledTextArea
                  style={{ height: '100%' }}
                  value={prescriptionFormData.note}
                  onChange={e => {
                    updateFormData({
                      prescription: {
                        note: { $set: e.target.value },
                      },
                    })
                  }}
                />
              ) : (
                `${checkIfProvided(prescriptionFormData.note)}`
              )}
            </EditMode>
            <NormalMode>
              {reviewPrescriptionData.notes.highlightValue && (
                <DiffTooltip message={reviewPrescriptionData.notes.message} />
              )}
              {reviewPrescriptionData.notes.value}
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>

      <RXListRow data-test-row="triplicate">
        <RXImageCell>
          <ListRowLabel>Triplicate:</ListRowLabel>
          <ListRowValue>{rxPrescriptionData.triplicate.value}</ListRowValue>
        </RXImageCell>
        <RXCenterCell>
          <ListRowLabel>Triplicate:</ListRowLabel>
          <ListRowValue color={reviewPrescriptionData.triplicate.highlightValue ? alertRed : bodyPrimaryColor}>
            <EditMode>
              {canUserEdit ? (
                <PaddedStyledTextInput
                  placeholder={'Enter the triplicate serial number...'}
                  onChange={e => {
                    updateFormData({
                      prescription: {
                        triplicate: { $set: e.target.value },
                      },
                    })
                  }}
                />
              ) : (
                `${checkIfProvided(prescriptionFormData.triplicate)}`
              )}
            </EditMode>
            <NormalMode>
              {reviewPrescriptionData.triplicate.highlightValue && (
                <DiffTooltip message={reviewPrescriptionData.triplicate.message} />
              )}
              {reviewPrescriptionData.triplicate.value}
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>

      <RXListRow data-test-row="icd10">
        <RXImageCell>
          <ListRowLabel>ICD 10:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.icd10s?.map(x => x.code).join(','))}</ListRowValue>
        </RXImageCell>
        <RXCenterCell>
          <ListRowLabel>ICD 10:</ListRowLabel>
          <ListRowValue>
            <EditMode>
              {canUserEdit ? (
                <Icd10SearchEditBox>
                  <Icd10Search
                    initialCodes={prescriptionFormData.icd10s || []}
                    updateIcd10Codes={selections => {
                      const ids = Object.keys(selections)
                      const icd10s = Object.values(selections)

                      updateFormData({
                        prescription: {
                          icd10: { $set: ids },
                          icd10s: { $set: icd10s },
                        },
                      })
                    }}
                  />
                </Icd10SearchEditBox>
              ) : (
                `${checkIfProvided(prescription.icd10s?.map(x => `${x.code} - ${x.text}`).join(', '))}`
              )}
            </EditMode>
            <NormalMode>
              {checkIfProvided(prescription.icd10s?.map(x => `${x.code} - ${x.text}`).join(', '))}
            </NormalMode>
          </ListRowValue>
        </RXCenterCell>
      </RXListRow>

      <RXListRow noStripe data-testid="border">
        <RXImageCell closeOffBottomBorder></RXImageCell>
      </RXListRow>
    </Box>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const SmallNumberInput = styled(NumericInput)`
  background-color: ${primaryBackgroundColor};
  width: 3.75rem;
`

const InsuranceTitleRow = styled.div``

const RXImageBox = styled.div`
  background-color: ${primaryBackgroundColor};
  height: 0;
  overflow-y: visible;
  position: relative;
  width: 30.1vw;
  z-index: 1;
`

const Icd10SearchEditBox = styled.div`
  width: 100%;
  margin-left: 0.125rem;
`

const EditModePadding = css`
  margin: 0.125rem;
`

const PaddedStyledTextInput = styled(StyledTextInput)`
  ${EditModePadding}
`

const PaddedStyledSelect = styled(StyledSelect)`
  ${EditModePadding}
`

const PaddedSmallNumberInput = styled(SmallNumberInput)`
  ${EditModePadding}
`

const DateCellPadding = styled.div`
  ${EditModePadding}
`

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  margin-right: 0.25rem;
`

const StyledDangerIcon = styled(DangerIcon)`
  margin-right: 0.25rem;
`

const DangerChip = styled(Chip)`
  width: 30px;
  height: 30px;
`

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const StyledTextArea = styled(TextArea)`
  min-height: 4rem;
  border: 0.125rem solid ${borderColor};
  background-color: ${primaryBackgroundColor};
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: 'Roboto';
  resize: none;
`

const NdcPackageSearchWrapper = styled.div`
  width: 100%;
`

const DrugNameWithWarning = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.25rem;
`

export default ReviewPrescription
