import 'typeface-roboto'
import { Suspense } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { Theme } from '@truepill/react-capsule'
import type { ThemeType } from 'grommet'
import { Grommet } from 'grommet'
import Auth0Provider from 'providers/Auth0Provider'
import HIDProviders from 'providers/HID'
import LaunchDarklyProvider from 'providers/LaunchDarklyProvider'
import OverlayProviders from 'providers/Overlays'
import PopUpProvider from 'providers/PopUpProvider'
import StoreProvider from 'providers/Store/StoreProvider'
import TaskProvider from 'providers/TaskProvider'
import VisionRouter from 'providers/VisionRouter'
import Layout from './components/Layout'
import Pages from './pages'
import PaneProvider from './providers/PaneProvider'
import PrintProvider from './providers/PrintProvider'
import TPCacheProvider from './providers/TPCacheProvider'
import { GlobalStyles, grommetTheme, CSSReset } from './styles'

const App = (): JSX.Element => {
  datadogRum.startView()
  return (
    <LaunchDarklyProvider>
      <Theme>
        <Grommet id="App" theme={grommetTheme as unknown as ThemeType}>
          <CSSReset />
          <GlobalStyles />
          <VisionRouter>
            <Auth0Provider>
              <StoreProvider>
                <TPCacheProvider>
                  <HIDProviders>
                    <OverlayProviders>
                      <PrintProvider>
                        <PaneProvider>
                          <PopUpProvider>
                            <TaskProvider initialTasks={[]}>
                              <Layout>
                                <Suspense fallback={<div>Loading...</div>}>
                                  <Pages />
                                </Suspense>
                              </Layout>
                            </TaskProvider>
                          </PopUpProvider>
                        </PaneProvider>
                      </PrintProvider>
                    </OverlayProviders>
                  </HIDProviders>
                </TPCacheProvider>
              </StoreProvider>
            </Auth0Provider>
          </VisionRouter>
        </Grommet>
      </Theme>
    </LaunchDarklyProvider>
  )
}

export default App
