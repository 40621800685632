import { useEffect, useRef, useContext } from 'react'
import useAnalytics from 'hooks/useAnalytics'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import type { HotKeyProps, HotKeyLevel, MetaKeys } from 'providers/HID/HotKeyProvider'
import { HotKeyContext } from 'providers/HID/HotKeyProvider'
import { GenericEvents } from 'services/analytics/events/genericEvents'

export { HotKeyLevel } from 'providers/HID/HotKeyProvider'

// useHotKey is a convenience function to register and deregister hotkeys
// on component mount. If your component stays in the dom and instead hides/shows
// then you'll need to call registerHotKey and deRegisterHotKey yourself
const useHotKey = (
  desiredHotKey: string,
  hotkeyLevel: HotKeyLevel,
  callback: (key: string, event: KeyboardEvent, wasDown?: boolean) => void,
  metaKeys: MetaKeys = {},
  id?: string,
  keyDown?: boolean,
): void => {
  const { registerHotKey, deRegisterHotKey } = useContext(HotKeyContext) as HotKeyProps
  const { trackEvent } = useAnalytics()
  const registerHotKeyRef = useRef(registerHotKey)
  const deRegisterHotKeyRef = useRef(deRegisterHotKey)
  const callbackRef = useRef(callback)

  const metaKeysRef = useAutoUpdatingRef(metaKeys)

  useEffect(() => {
    registerHotKeyRef.current = registerHotKey
    deRegisterHotKeyRef.current = deRegisterHotKey
    callbackRef.current = callback
  }, [registerHotKey, deRegisterHotKey, callback])

  useEffect(() => {
    registerHotKeyRef.current(
      desiredHotKey,
      hotkeyLevel,
      (...args) => {
        const metaKeysString = Object.keys(metaKeys).join('-')
        const hotkey = metaKeysString ? `${metaKeysString}-${desiredHotKey}` : desiredHotKey
        trackEvent(GenericEvents.HOT_KEY_USE.key, {
          hotkey: hotkey,
        })
        callbackRef.current(...args)
      },
      keyDown,
      metaKeysRef.current,
      id,
    )
    const currentMetaKeys = metaKeysRef.current
    return () => deRegisterHotKeyRef.current(desiredHotKey, hotkeyLevel, currentMetaKeys, id)
  }, [id, desiredHotKey, hotkeyLevel, keyDown, metaKeysRef])
}

export default useHotKey
