import { useState } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import BreadCrumb from 'components/BreadCrumb'
import { PageContent, PageHeading, StickyPageHeadingContainer } from 'components/PageStructure'
import RxHeading from 'components/RxHeading'
import { NormalMode } from 'components/RXPageStructure'
import { RXTable, RXSegment, RXRowTable } from 'components/RXTable'
import RXTopBanner from 'components/RXTopBanner'
import ToolBar, {
  OrderButton,
  NotesButton,
  MedicalHistoryButton,
  DataButton,
  MonographButton,
  LogButton,
  AttachmentsButton,
} from 'components/ToolBar'
import { GET_ORDER_INSURANCE_OPTION_IMAGES } from 'gql'
import useClaim from 'hooks/navigation/useClaim'
import { uniqueLogEntries } from 'hooks/navigation/useLogs'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import styled from 'styled-components'
import type { TPOSDocument, Fill, Order, Patient, Prescription, Log, RXFillRequest } from 'types'
import { getFillLevelNotes, getFillParentNotes, getOrderAttachmentsCount, getParentAttachments } from 'utils'
import ActionButtons from '../claims/ActionButtons'
import CoverMyMeds from './coverMyMeds'
import ReviewPatient from './ReviewSections/ReviewPatient'
import ReviewPayer from './ReviewSections/ReviewPayer'
import ReviewPrescriber from './ReviewSections/ReviewPrescriber'
import ReviewPrescription from './ReviewSections/ReviewPrescription'
import TopSection from './ReviewSections/TopSection'

// This is the page to view and handle Manual Prior Authorizations in TPOS,
// do not confuse with the Automated Prior Authorizations page

type ManualPriorAuthViewProps = {
  order: Order
  fill: Fill
  prescription: Prescription
  patient: Patient
  rxFillRequest: RXFillRequest
  docs?: TPOSDocument[]
  logs: Log[]
}

const ManualPriorAuthView = ({
  fill,
  order,
  prescription,
  patient,
  rxFillRequest,
  docs,
  logs,
}: ManualPriorAuthViewProps): JSX.Element => {
  // initialize variables from the backend
  useClaim({ order, fill, patient })
  const [, setRunningClaim] = useState(false)

  useCriticalNotesModal({ order, prescription, logs, showPatientNotes: true })

  const originalCoverMyMedsKey = rxFillRequest?.coverMyMedsKey
  const [updatedCMMKey, setUpdatedCMMKey] = useState(originalCoverMyMedsKey ?? '')

  const parentNotes = getFillParentNotes(logs, order._id)
  const fillLevelNotes = getFillLevelNotes(logs, fill._id, order._id)

  const orderAttachments = getOrderAttachmentsCount(docs)
  const parentAttachments = getParentAttachments(docs)
  const { data: { getInsuranceOptionImages: insuranceImages = [] } = {} } = useQuery(
    GET_ORDER_INSURANCE_OPTION_IMAGES,
    { variables: { orderId: order._id } },
  )

  return (
    <>
      <StyledStickyPageHeadingContainer>
        <RXTopBanner order={order} rxFillRequest={rxFillRequest} />
        <BreadCrumb />
        <PageHeading>
          <RxHeading item={order} prescription={prescription} fill={fill} patient={patient} />
          <NormalMode>
            <ActionButtons
              fill={fill}
              item={order}
              patient={patient}
              prescription={prescription}
              isManualPriorAuth
              setRunningClaim={setRunningClaim}
            />
          </NormalMode>
        </PageHeading>
      </StyledStickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <TopSection fill={fill} />
                <RXThreeColumn>
                  <Container>
                    <ReviewPrescription prescription={prescription} fill={fill} />
                  </Container>
                  <MarginLeft>
                    <ReviewPatient patient={patient} />
                    <ReviewPrescriber prescription={prescription} />
                  </MarginLeft>
                  <MarginLeft>
                    <ReviewPayer item={order} insuranceImages={insuranceImages} />
                    <CoverMyMeds order={order} fill={fill} setCMMKeyVal={setUpdatedCMMKey} valCMMKey={updatedCMMKey} />
                  </MarginLeft>
                </RXThreeColumn>
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar>
          <LogButton logs={logs ? uniqueLogEntries(logs) : []} fillId={fill._id} prescriptionId={prescription._id} />
          <NotesButton
            notesCount={fillLevelNotes.length}
            showBadge={parentNotes.length > 0 || fillLevelNotes.length > 0}
          />
          <AttachmentsButton
            attachmentsCount={orderAttachments}
            showBadge={parentAttachments || orderAttachments > 0}
          />
          <MedicalHistoryButton fillId={fill._id} patientId={patient._id} />
          <OrderButton order={order} />
          <DataButton prescription={prescription} />
          <MonographButton ndc={fill.dispensed.ndc} />
        </ToolBar>
      </PageContent>
    </>
  )
}

const Container = styled.div``

const RXThreeColumn = styled.div`
  grid-template-columns: minmax(26.5rem, 1fr) minmax(26.5rem, 1fr) minmax(26.5rem, 1fr);
  display: grid;
`
const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`
RXViewContainer.displayName = 'RXViewContainer'

const MarginLeft = styled.div`
  margin-left: 1.25rem;
`
MarginLeft.displayName = 'MarginLeft'

const StyledStickyPageHeadingContainer = styled(StickyPageHeadingContainer)`
  z-index: 2;
`
StyledStickyPageHeadingContainer.displayName = 'StyledStickyPageHeadingContainer '

export default ManualPriorAuthView
