import { useEffect, useState } from 'react'
import MultiSelect, { type MultiSelectOption } from 'components/MultiSelect'
import Select from 'components/Select'
import { ShippingMethod } from 'types'
import { shippingMethodToName, camelCaseToHumanReadable } from 'utils'

type BaseProps<T extends ShippingMethod> = {
  value?: string
  className?: string
}

type SingleSelectProps<T extends ShippingMethod> = BaseProps<T> & {
  multiSelect?: false
  onSelect: (shippingMethod: T) => void
}

type MultiSelectProps<T extends ShippingMethod> = BaseProps<T> & {
  multiSelect: true
  onSelect: (shippingMethods: T[]) => void
}

type ShippingSelectProps<T extends ShippingMethod> = SingleSelectProps<T> | MultiSelectProps<T>

// Define the shipping methods that are not selectable
const shippingMethodsNotSelectable = [ShippingMethod.UspsFirst.toString()]

const ShippingSelect = <T extends ShippingMethod>(props: ShippingSelectProps<T>): JSX.Element => {
  const { onSelect, value, className, multiSelect } = props
  const [allOptionsChecked, setAllOptionsChecked] = useState(false)

  // Convert ShippingMethod to options array, excluding UspsFirst
  const multiSelectOptions: MultiSelectOption<T>[] = [
    { label: 'Select All', value: 'All' as T, selectAll: true },
    ...Object.values(shippingMethodToName)
      .filter(option => {
        return !shippingMethodsNotSelectable.includes(ShippingMethod[option as keyof typeof ShippingMethod].toString())
      })
      .map(option => ({
        label: camelCaseToHumanReadable(option),
        value: ShippingMethod[option as keyof typeof ShippingMethod] as T,
      })),
  ]
  const singleSelectOptions = multiSelectOptions.slice(1)

  // Define the selected value
  const selectedValue =
    typeof value === 'string'
      ? {
          label: camelCaseToHumanReadable(shippingMethodToName[value]),
          value: value as T,
        }
      : undefined
  const multiSelectValues =
    value?.includes('All') || allOptionsChecked
      ? multiSelectOptions.map(opt => opt.value)
      : Array.isArray(value)
      ? value
      : value?.split(',') || []

  return multiSelect ? (
    <MultiSelect
      label="Shipping method"
      value={multiSelectValues}
      placeholder="Select shipping methods…"
      options={multiSelectOptions}
      onChange={values => {
        if (values.includes('All')) {
          onSelect(['All'] as unknown as T[])
          setAllOptionsChecked(true)
        } else {
          onSelect(values as T[])
          setAllOptionsChecked(false)
        }
      }}
    />
  ) : (
    <Select
      disableClear
      value={selectedValue}
      className={className}
      placeholder={'Select shipping method…'}
      options={singleSelectOptions}
      onChange={([option]) => onSelect(option?.value as T)}
    />
  )
}

export default ShippingSelect
