export const PV1Events = {
  PV1_START: {
    key: 'PV1_START',
    name: 'Start PV1',
    description: 'User started to PV1 a fill',
  },
  PV1_END: {
    key: 'PV1_END',
    name: 'End PV1',
    description: 'User ended PV1 in a fill',
  },
  PV1_CHANGE_DISPENSED_CLICK: {
    key: 'PV1_CHANGE_DISPENSED_CLICK',
    name: 'Change Dispensed Click',
    description: 'User clicked on the change dispensed button',
  },
  PV1_CHANGE_DISPENSED_NDC: {
    key: 'PV1_CHANGE_DISPENSED_NDC',
    name: 'Change Dispensed NDC',
    description: 'User changed the dispensed NDC in the change dispensed modal',
  },
  PV1_CHANGE_DISPENSED_QUANTITY: {
    key: 'PV1_CHANGE_DISPENSED_QUANTITY',
    name: 'Change Dispensed Quantity',
    description: 'User changed the dispensed quantity in the change dispensed modal',
  },
  PV1_CHANGE_DISPENSED_DS: {
    key: 'PV1_CHANGE_DISPENSED_DS',
    name: 'Change Dispensed DS',
    description: 'User changed the dispensed DS in the change dispensed modal',
  },
  PV1_CHANGE_DISPENSED_DIRECTIONS: {
    key: 'PV1_CHANGE_DISPENSED_DIRECTIONS',
    name: 'Change Dispensed Directions',
    description: 'User changed the dispensed directions in the change dispensed modal',
  },
  PV1_CHANGE_DISPENSED_UPDATE: {
    key: 'PV1_CHANGE_DISPENSED_UPDATE',
    name: 'Change Dispensed Update',
    description: 'User clicked on the update button in the change dispensed modal',
  },
  PV1_EDIT_CLICK: {
    key: 'PV1_EDIT_CLICK',
    name: 'Edit Click',
    description: 'User clicked on the edit button in a PV1 fill',
  },
  PV1_EDIT_DIRECTIONS: {
    key: 'PV1_EDIT_DIRECTIONS',
    name: 'Edit Directions',
    description: 'User edited the directions in a PV1 fill',
  },
  PV1_EDIT_UPDATE_CLICK: {
    key: 'PV1_EDIT_UPDATE_CLICK',
    name: 'Edit Update Click',
    description: 'User clicked on the update button while editing a PV1 fill',
  },
  PV1_MEDICATION_HISTORY_CLICK: {
    key: 'PV1_MEDICATION_HISTORY_CLICK',
    name: 'Medication History Click',
    description: 'User clicked on the medication history button in a PV1 fill',
  },
  PV1_LOG_CLICK: {
    key: 'PV1_LOG_CLICK',
    name: 'Log Click',
    description: 'User clicked on the log button in a PV1 fill',
  },
  PV1_EDIT_CLINICAL_HISTORY_CLICK: {
    key: 'PV1_EDIT_CLINICAL_HISTORY_CLICK',
    name: 'Edit Clinical History Click',
    description: 'User clicked on the edit clinical history button in a PV1 fill',
  },
  PV1_TOGGLE_RX_IMAGE: {
    key: 'PV1_TOGGLE_RX_IMAGE',
    name: 'Toggle Rx Image',
    description: 'User clicked x to toggle the Rx image in a PV1 fill',
  },
} as const
