import AddressEntry from 'components/AddressEntry'
import {
  ListRowLabel,
  ListRowValue,
  SmallPaddingBlock,
  RaisedAnchor,
  StyledRXImageCell,
  RXListRowSingle,
  ImageFilledHeader,
} from 'components/RXTable'
import moment from 'moment'
import styled from 'styled-components'
import type { Patient } from 'types'
import { capitalize, checkIfProvided, formatPhoneNumber } from 'utils'

type ReviewPatientProps = {
  patient: Patient
}

const ReviewPatient = ({ patient }: ReviewPatientProps): JSX.Element => {
  return (
    <>
      <TitleRow>
        <SmallPaddingBlock />
        <RXImageInsuranceFilledHeader>
          <RaisedAnchor id={`ReviewPatient`} />
          Patient
        </RXImageInsuranceFilledHeader>
      </TitleRow>

      <RXListRowSingle>
        <StyledRXImageCell closeOffTopBorder>
          <ListRowLabel>Name:</ListRowLabel>
          <ListRowValue data-private>
            {patient.firstName} {patient.lastName}
          </ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>DOB:</ListRowLabel>
          <ListRowValue data-private>{checkIfProvided(moment(patient.dob).format('MM/DD/YYYY'))}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>Gender:</ListRowLabel>
          <ListRowValue data-private>{checkIfProvided(capitalize(patient.gender))}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>Address:</ListRowLabel>
          <AddressEntry data-private address={patient.address?.home} />
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell closeOffBottomBorder>
          <ListRowLabel>Phone:</ListRowLabel>
          <ListRowValue data-private>{checkIfProvided(formatPhoneNumber(patient.contacts?.phone))}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
    </>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const TitleRow = styled.div``

export default ReviewPatient
