import { useCallback } from 'react'
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg'
import { ReactComponent as DataTransferIcon } from 'assets/icons/data-transfers.svg'
import { ReactComponent as DataIcon } from 'assets/icons/data.svg'
import { ReactComponent as EditReversalInfo } from 'assets/icons/edit-reversal.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as TreeIcon } from 'assets/icons/git-branch.svg'
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg'
import { ReactComponent as InventoryIcon } from 'assets/icons/inventory.svg'
import { ReactComponent as LogIcon } from 'assets/icons/log.svg'
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg'
import { ReactComponent as MonographIcon } from 'assets/icons/monograph.svg'
import { ReactComponent as NotesIcon } from 'assets/icons/notes.svg'
import { ReactComponent as OrderIcon } from 'assets/icons/order.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import { ReactComponent as CoverageIcon } from 'assets/icons/umbrella.svg'
import CoverageInfo from 'components/CoverageInfo'
import DemographicInfo from 'components/DemographicInfo'
import ErrorBoundary from 'components/ErrorBoundary'
import IconWrapper from 'components/IconWrapper'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import MonographInfo from 'components/MonographInfo'
import SideToolBar, { ToolButton } from 'components/SideToolBar'
import useErrorToast from 'hooks/toast/useErrorToast'
import useAnalytics from 'hooks/useAnalytics'
import useEditMode from 'hooks/useEditMode'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useOrderLock from 'hooks/useOrderLock'
import usePane from 'hooks/usePane'
import ClaimSummaryModal from 'modals/ClaimSummaryModal'
import DispensingDecisionModal from 'modals/DispensingDecisionModal'
import EscriptDataModal from 'modals/EscriptDataModal'
import ItemReturnListModal from 'modals/ItemReturnListModal'
import LogModal from 'modals/LogModal'
import MedicalHistoryModal from 'modals/MedicalHistoryModal'
import OrderModal from 'modals/OrderModal'
import RXDataModal from 'modals/RXDataModal'
import SimpleModal from 'modals/SimpleModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { PV1Events } from 'services/analytics/events/pv1Events'
import styled from 'styled-components'
import { bodyPrimaryColor, lightRed } from 'styles/styleVariables'
import type {
  Fill,
  Log,
  Order,
  Patient,
  Prescription,
  RXFillRequest,
  Escript,
  ChildProps,
  CopayRequest,
  User,
} from 'types'

type ToolBarProps = { paddingTop?: boolean } & ChildProps

const ToolBar = ({ children, paddingTop }: ToolBarProps): JSX.Element => {
  const [editMode] = useEditMode()

  return (
    <ErrorBoundary>
      <SideToolBar paddingTop={paddingTop} visible={!editMode}>
        {children}
      </SideToolBar>
    </ErrorBoundary>
  )
}

type DataButtonProps = { prescription: Prescription }

export const DataButton = ({ prescription }: DataButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const showData = useCallback(() => {
    showModal(() => <RXDataModal prescription={prescription} />)
  }, [prescription, showModal])

  useHotKey('D', HotKeyLevel.normal, showData, { ctrl: true, shift: true })

  return <ToolButton label={'Data'} tooltipText={'Data (CTRL-SHIFT-D)'} icon={DataIcon} clickCallback={showData} />
}

type EscriptDataButtonProps = { escript: Escript }

export const EscriptDataButton = ({ escript }: EscriptDataButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const showEscriptData = useCallback(() => {
    showModal(() => <EscriptDataModal escript={escript} />)
  }, [escript, showModal])

  useHotKey('D', HotKeyLevel.normal, showEscriptData, { ctrl: true, shift: true })

  return (
    <ToolButton label={'Data'} tooltipText={'Data (CTRL-SHIFT-D)'} icon={DataIcon} clickCallback={showEscriptData} />
  )
}

type OrderButtonProps = { order: Order }

export const OrderButton = ({ order }: OrderButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const showOrder = useCallback(() => {
    showModal(() => <OrderModal order={order} />)
  }, [order, showModal])
  useHotKey('O', HotKeyLevel.normal, showOrder, { ctrl: true, shift: true })
  return <ToolButton label="Order" tooltipText="Order (CTRL-SHIFT-O)" icon={OrderIcon} clickCallback={showOrder} />
}

export const MedicalHistoryButton = (props: { patientId: Patient['_id']; fillId?: Fill['_id'] }): JSX.Element => {
  const { patientId, fillId } = props
  const { showModal } = useModalContext()
  const { trackPV1Event } = useAnalytics()
  const showMedicalHistory = useCallback(() => {
    trackPV1Event(PV1Events.PV1_MEDICATION_HISTORY_CLICK.key, {
      fillId: fillId,
    })
    showModal(() => <MedicalHistoryModal patientId={patientId} fillId={fillId} />)
  }, [patientId, fillId, showModal])

  useHotKey('H', HotKeyLevel.normal, showMedicalHistory, {
    ctrl: true,
    shift: true,
  })

  return (
    <ToolButton
      label={'Medication history'}
      data-testid="MedicationHistory"
      tooltipText={'Medication history (CTRL-SHIFT-H)'}
      icon={TimeIcon}
      clickCallback={showMedicalHistory}
    />
  )
}

export const EditPriorAuthorizationButton = ({ disabled = false }: EditButtonProps): JSX.Element => {
  const [editMode, setEditMode] = useEditMode()

  const onClick = async () => {
    setEditMode(true)
  }

  useHotKey('E', HotKeyLevel.normal, onClick, { ctrl: true, shift: true })

  return (
    <ToolButton
      disabled={disabled}
      label="Edit"
      active={editMode}
      icon={EditIcon}
      tooltipText="Edit (CTRL-SHIFT-E)"
      clickCallback={onClick}
    />
  )
}

type EditEdiButtonProps = { setDisplayEdiEdit: (val: boolean) => void }

export const EditEdiButton = ({ setDisplayEdiEdit }: EditEdiButtonProps): JSX.Element => {
  return (
    <ToolButton
      label={'Edit Edi Info'}
      tooltipText={'Edit Edi Info'}
      icon={DataTransferIcon}
      clickCallback={() => setDisplayEdiEdit(true)}
    />
  )
}

type EditReversalInfoProps = { setDisplayEditReversalInfo: (val: boolean) => void }

export const EditReversalInfoButton = ({ setDisplayEditReversalInfo }: EditReversalInfoProps): JSX.Element => {
  return (
    <ToolButton
      label={'Edit Reversal Info'}
      tooltipText={'Edit Reversal Info'}
      icon={EditReversalInfo}
      clickCallback={() => setDisplayEditReversalInfo(true)}
    />
  )
}

type LogButtonProps = {
  logs: Log[]
  fillId?: Fill['_id']
  orderId?: Order['_id']
  prescriptionId?: Prescription['_id']
  patientId?: Patient['_id']
  escriptId?: Escript['_id']
  updatedUserId?: User['_id']
  copayRequestId?: CopayRequest['_id']
  isCopay?: boolean
}

export const LogButton = ({
  orderId,
  fillId,
  prescriptionId,
  patientId,
  escriptId,
  copayRequestId,
  updatedUserId,
  logs = [],
  isCopay = false,
}: LogButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const { trackPV1Event } = useAnalytics()
  const showLog = useCallback(() => {
    trackPV1Event(PV1Events.PV1_LOG_CLICK.key, {
      fillId,
    })
    showModal(() => (
      <LogModal
        orderId={orderId}
        fillId={fillId}
        prescriptionId={prescriptionId}
        patientId={patientId}
        escriptId={escriptId}
        logs={logs}
        updatedUserId={updatedUserId}
        copayRequestId={copayRequestId}
        isCopay={isCopay}
      />
    ))
  }, [fillId, orderId, prescriptionId, copayRequestId, escriptId, logs, showModal])

  useHotKey('L', HotKeyLevel.normal, showLog, { ctrl: true, shift: true })

  return <ToolButton label={'Log'} tooltipText={'Log (CTRL-SHIFT-L)'} icon={LogIcon} clickCallback={showLog} />
}

type MessageButtonProps = { hideTooltip?: boolean }

export const MessageButton = ({ hideTooltip }: MessageButtonProps): JSX.Element => {
  const { paneVisible, togglePane } = usePane('Message')
  return (
    <ToolButton
      active={paneVisible}
      tooltipText={hideTooltip ? undefined : 'Message (F3)'}
      label={'Messages'}
      icon={MessageIcon}
      clickCallback={togglePane}
    />
  )
}

type NotesButtonProps = { notesCount?: number; showBadge?: boolean; badgeBackgroundColor?: string }

export const NotesButton = ({ notesCount, showBadge, badgeBackgroundColor }: NotesButtonProps): JSX.Element => {
  const { paneVisible, togglePane } = usePane('Note')
  useHotKey('N', HotKeyLevel.normal, togglePane, { ctrl: true, shift: true })
  return (
    <>
      <ToolButton
        active={paneVisible}
        tooltipText={'Note (CTRL-SHIFT-N)'}
        label={'Notes'}
        icon={NotesIcon}
        clickCallback={togglePane}
      ></ToolButton>
      {showBadge && (
        <BadgeNumber backgroundColor={badgeBackgroundColor}>
          <span>{(notesCount || 0) > 0 && notesCount}</span>{' '}
        </BadgeNumber>
      )}
    </>
  )
}

type AttachmentButtonProps = {
  attachmentsCount?: number
  showBadge?: boolean
}

export const AttachmentsButton = ({ attachmentsCount, showBadge }: AttachmentButtonProps): JSX.Element => {
  const { paneVisible, togglePane } = usePane('Attachments')
  useHotKey('A', HotKeyLevel.normal, togglePane, { ctrl: true, shift: true })
  return (
    <>
      <ToolButton
        active={paneVisible}
        tooltipText={'Attachments (CTRL-SHIFT-A)'}
        label={'Attachments'}
        icon={AttachIcon}
        clickCallback={togglePane}
      />
      {showBadge && (
        <BadgeNumber>
          {' '}
          <span>{(attachmentsCount || 0) > 0 && attachmentsCount}</span>{' '}
        </BadgeNumber>
      )}
    </>
  )
}

export const ImageButton = (): JSX.Element => {
  const { paneVisible, togglePane } = usePane('Image')

  useHotKey('I', HotKeyLevel.normal, togglePane, { ctrl: true, shift: true })
  return (
    <ToolButton
      active={paneVisible}
      tooltipText={'Image (CTRL-SHIFT-I)'}
      label={'Images'}
      icon={ImageIcon}
      clickCallback={togglePane}
    />
  )
}

type EditButtonProps = { orderId?: string; disabled?: boolean; onClickCallback?: () => void }

export const EditButton = ({ orderId, disabled = false, onClickCallback }: EditButtonProps): JSX.Element => {
  const [editMode, setEditMode] = useEditMode()
  const { orderLockedBy, isOrderLockedByMe, setOrderLock } = useOrderLock(orderId)

  const showErrorToast = useErrorToast(true)

  const onClick = async () => {
    if (onClickCallback) {
      onClickCallback()
    }
    if (orderLockedBy && !isOrderLockedByMe) {
      return showErrorToast(`Order is Locked By ${orderLockedBy.firstName} ${orderLockedBy.lastName}`)
    }

    if (!orderId) {
      setEditMode(true)
      return
    }

    if (!isOrderLockedByMe) await setOrderLock()

    setEditMode(true)
  }

  useHotKey('E', HotKeyLevel.normal, onClick, { ctrl: true, shift: true })

  return (
    <ToolButton
      disabled={disabled}
      label="Edit"
      active={editMode}
      icon={EditIcon}
      tooltipText="Edit (CTRL-SHIFT-E)"
      clickCallback={onClick}
    />
  )
}

type EditOutboundTransferButtonProps = { outboundTransferId: string }

export const EditOutboundTransferButton = ({ outboundTransferId }: EditOutboundTransferButtonProps): JSX.Element => {
  const onClick = () => {
    //TODO: Implement edit funcionality
  }

  useHotKey('E', HotKeyLevel.normal, onClick, { ctrl: true, shift: true })

  return <ToolButton label="Edit" icon={EditIcon} tooltipText="Edit (CTRL-SHIFT-E)" clickCallback={onClick} />
}

type DemographicInformationButtonProps = { patientId: Patient['_id'] }

export const DemographicInformationButton = ({ patientId }: DemographicInformationButtonProps): JSX.Element => {
  const { showModal, dismissModal } = useModalContext()

  const showData = useCallback(() => {
    showModal(() => (
      <ModalWrapper id="DemographicInfoModal">
        <ModalHeader marginBottom="1rem">
          <IconWrapper>
            <PersonIcon fill={bodyPrimaryColor} />
          </IconWrapper>
          <h2>Demographic information</h2>
        </ModalHeader>

        <DemographicInfo patientId={patientId} />
      </ModalWrapper>
    ))
  }, [showModal, dismissModal, patientId])

  useHotKey('bd', HotKeyLevel.normal, showData)
  return (
    <ToolButton
      label={'Demographic information'}
      icon={PersonIcon}
      tooltipText={'Demographic information (B-D)'}
      clickCallback={showData}
    />
  )
}

export const CoverageInformationButton = ({ patientId }: DemographicInformationButtonProps): JSX.Element => {
  const { showModal, dismissModal } = useModalContext()

  const showData = useCallback(() => {
    showModal(() => (
      <SimpleModal
        title={
          <ModalHeader>
            <IconWrapper>
              <CoverageIcon fill={bodyPrimaryColor} />
            </IconWrapper>
            <h2>Coverage information</h2>
          </ModalHeader>
        }
        constrainWidth={false}
        confirmationCallback={dismissModal}
        cancelCallback={dismissModal}
        confirmButtonLabel={'Close'}
        hideCancel
      >
        <CoverageInfo patientId={patientId} />
      </SimpleModal>
    ))
  }, [showModal, dismissModal, patientId])

  useHotKey('bc', HotKeyLevel.normal, showData)

  return (
    <ToolButton
      label={'Coverage information'}
      icon={CoverageIcon}
      tooltipText={'Coverage information (B-C)'}
      clickCallback={showData}
    />
  )
}

type ClaimSummaryButtonProps = {
  fill: Fill
  order: Order
  rxFillRequest: RXFillRequest
  prescription: Prescription
  allowDisable?: boolean
}

export const ClaimSummaryButton = ({
  fill,
  order,
  rxFillRequest,
  prescription,
  allowDisable,
}: ClaimSummaryButtonProps): JSX.Element => {
  const { showModal, dismissModal } = useModalContext()
  const disableButton = allowDisable ? !rxFillRequest.adjudication?.runSuccessful : false
  const showData = useCallback(() => {
    showModal(() => (
      <ClaimSummaryModal fill={fill} order={order} rxFillRequest={rxFillRequest} prescription={prescription} />
    ))
  }, [showModal, dismissModal, fill, order, rxFillRequest])

  // useHotKey('bc', HotKeyLevel.normal, showData)

  return (
    <ToolButton
      label={'Claim Summary'}
      icon={CoverageIcon}
      tooltipText={disableButton ? 'No paid claim on this insurance fill' : 'Claim Summary ()'}
      clickCallback={showData}
      disabled={disableButton}
    />
  )
}

type MonographButtonProps = { ndc: string }

export const MonographButton = ({ ndc }: MonographButtonProps): JSX.Element => {
  const { showModal, dismissModal } = useModalContext()

  const showData = useCallback(() => {
    showModal(() => (
      <ModalWrapper id="MonographModal">
        <ModalHeader marginBottom="1rem">
          <IconWrapper>
            <MonographIcon fill={bodyPrimaryColor} />
          </IconWrapper>
          <h2>Monograph</h2>
        </ModalHeader>
        <MonographInfo ndc={ndc} />
      </ModalWrapper>
    ))
  }, [showModal, dismissModal, ndc])

  useHotKey('M', HotKeyLevel.normal, showData, { ctrl: true, shift: true })

  return (
    <ToolButton
      label={'Monograph'}
      icon={MonographIcon}
      tooltipText={'Monograph (CTRL-SHIFT-M)'}
      clickCallback={showData}
    />
  )
}

type ReturnItemListButtonProps = { order: Order }

export const ReturnItemListButton = ({ order }: ReturnItemListButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const showReturns = useCallback(() => {
    showModal(() => <ItemReturnListModal order={order} />)
  }, [showModal, order])

  useHotKey('U', HotKeyLevel.normal, showReturns, { ctrl: true, shift: true })

  return (
    <ToolButton
      label={'Show returns'}
      icon={InventoryIcon}
      tooltipText={'Show returns (CTRL-SHIFT-U)'}
      clickCallback={showReturns}
    />
  )
}

type DispensingDecisionButtonProps = {
  prescription: Prescription
  fillId: Fill['_id']
}

export const DispensingDecisionButton = ({ prescription, fillId }: DispensingDecisionButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const showDispensingDecision = useCallback(() => {
    showModal(() => <DispensingDecisionModal prescription={prescription} fillId={fillId} />)
  }, [showModal])

  useHotKey('T', HotKeyLevel.normal, showDispensingDecision, { ctrl: true, shift: true })

  return (
    <ToolButton
      label={'Dispensing decision'}
      tooltipText={'Dispensing decision (CTRL-SHIFT-T)'}
      icon={TreeIcon}
      clickCallback={showDispensingDecision}
    />
  )
}

const BadgeNumber = styled.span<{ backgroundColor?: string }>`
  height: 0px;
  > span {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor ?? lightRed};
    font-size: 12px;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    padding: 3px;
    position: relative;
    min-width: 1.1rem;
    min-height: 0.8rem;
    height: 1.1rem;
    left: 0.9rem;
    top: -3.3rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
`
export default ToolBar
