import { Select as CapsuleSelect } from '@truepill/react-capsule'
import Select from 'components/Select'

enum PrintStatusSelectOptions {
  All = 'All',
  Unprinted = 'Unprinted',
  Printed = 'Printed',
}

const printStatusValueMap: Record<PrintStatusSelectOptions, null | boolean> = {
  [PrintStatusSelectOptions.All]: null,
  [PrintStatusSelectOptions.Unprinted]: false,
  [PrintStatusSelectOptions.Printed]: true,
}

type PrintStatusSelectProps = {
  onSelect: (printStatus: boolean | null) => void
  value?: boolean | null
  className?: string
  clearable?: boolean
  withCapsule?: boolean
}

const options = Object.entries(printStatusValueMap).map(([label, value]) => ({
  label,
  value,
}))

const PrintStatusSelect = ({ onSelect, value, className, withCapsule }: PrintStatusSelectProps): JSX.Element => {
  const defaultValue = {
    label: PrintStatusSelectOptions.All,
    value: printStatusValueMap[PrintStatusSelectOptions.All],
  }

  const selectedValue = options.find(option => option.value === value) ?? defaultValue

  return withCapsule ? (
    <CapsuleSelect
      label="Print status"
      value={selectedValue}
      placeholder="Select print status…"
      options={options}
      selectedKey="label"
      onChange={option => option && onSelect(option.value)}
    />
  ) : (
    <Select
      disableClear={true}
      value={selectedValue}
      className={className}
      placeholder="Select print status…"
      options={options}
      onChange={([option]) => option && onSelect(option.value)}
    />
  )
}

export default PrintStatusSelect
