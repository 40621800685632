import { useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import AutoCompleteOption from 'components/MedicationAutocompleteOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { SEARCH_ORDER_MEDICATIONS } from 'gql'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { MedicationSuggestionItem, OrderMedicationSearchResult } from 'types'

type OrderMedicationSearchOptionsProps = { isVisible?: boolean; hotKey?: string; isModal?: boolean }

const OrderMedicationSearchOptions = ({
  isModal,
  hotKey = 'm',
  isVisible,
}: OrderMedicationSearchOptionsProps): JSX.Element => {
  const queue = useFulfillmentQueue() as FulfillmentQueue
  const { currentLocation, routeToMergedQuery } = usePlusClient()
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')
  const { data, loading, error } = useQuery(SEARCH_ORDER_MEDICATIONS, {
    skip: debouncedSearchTerm.length < 3,
    variables: {
      name: debouncedSearchTerm.length >= 3 ? debouncedSearchTerm : undefined,
      orderStages: queue?.orderStages || [],
      fillStages: queue?.fillStages || [],
      locationId: currentLocation.queryMap?.locationId,
      ...(queue?.filters?.inTriage && { inTriage: queue.filters?.inTriage }),
    },
  })

  // select the correct query returns based on the search param sent
  const options: MedicationSuggestionItem[] | undefined =
    debouncedSearchTerm.length >= 3 && data?.searchMedications && data.searchMedications.length > 0
      ? data.searchMedications.map((searchResult: OrderMedicationSearchResult) => ({
          name: searchResult._id,
          count: searchResult.count,
        }))
      : undefined
  const useSimplifiedMedicationSearch = useFlag(LaunchDarkly.FeatureFlags.USE_SIMPLIFIED_MEDICATION_SEARCH)
  const medications = currentLocation?.queryMap?.medications || []
  const ndcs = currentLocation?.queryMap?.ndcs || []

  return (
    <>
      <SearchAutoComplete
        withCapsule
        label="Medications"
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={bodySecondaryColor}
        autoCompleteSuggestions={
          error
            ? [{ error: `Error searching Medications: ${JSON.stringify(error)}` }]
            : loading
            ? [{ loading }]
            : options ?? [{ noResults: true }]
        }
        value={searchTerm}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as MedicationSuggestionItem)} />}
        onChange={newValue => setSearchTerm(newValue)}
        placeholder={'Search by name or NDC...'}
        onSelect={chosen => {
          setSearchTerm('')
          if (!chosen) {
            return
          }

          if (useSimplifiedMedicationSearch) {
            if (!medications.includes(chosen.name))
              return routeToMergedQuery({
                medications: [...medications, chosen.name],
                skus: [],
                otcMedications: [],
                page: 0,
              })
          } else {
            // If the chosen name is all numbers, it's an NDC
            if (/^\d+$/.test(chosen.name) && !ndcs.includes(chosen.name)) {
              return routeToMergedQuery({ ndcs: [...ndcs, chosen.name], skus: [], otcMedications: [], page: 0 })
            }

            if (!medications.includes(chosen.name))
              return routeToMergedQuery({
                medications: [...medications, chosen.name],
                skus: [],
                otcMedications: [],
                page: 0,
              })
          }
        }}
      />
      <SearchResultLozengeList>
        {medications.map((name: string, idx: number) => {
          return (
            <SearchResultLozenge
              key={idx}
              closeCallback={() => {
                if (medications.includes(name)) {
                  const newMeds = [...medications]
                  newMeds.splice(medications.indexOf(name), 1)

                  routeToMergedQuery({ medications: newMeds, page: 0 })
                }
              }}
            >
              <p>{name}</p>
            </SearchResultLozenge>
          )
        })}
        {!useSimplifiedMedicationSearch &&
          ndcs.map((name: string, idx: number) => {
            return (
              <SearchResultLozenge
                key={idx}
                closeCallback={() => {
                  if (ndcs.includes(name)) {
                    const newNDCs = [...ndcs]
                    newNDCs.splice(ndcs.indexOf(name), 1)

                    routeToMergedQuery({ ndcs: newNDCs, page: 0 })
                  }
                }}
              >
                <p>{name}</p>
              </SearchResultLozenge>
            )
          })}
      </SearchResultLozengeList>
    </>
  )
}

export default OrderMedicationSearchOptions
