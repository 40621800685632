import { useEffect, useState } from 'react'
import { LaunchDarkly } from '@truepill/tpos-types'
import { useClient, useFlags } from 'providers/LaunchDarklyProvider'

interface FeatureFlagsState {
  newDurInputsModalEnabled: boolean
  hideConsentOnPv1: boolean
  hideRefillsWrittenField: boolean
  highlightPv1Differences: boolean
  multiPatientOrder: boolean
  shouldApplyInterfaceImprovements: boolean
  showCopayCollected: boolean
}

const initialFlagsState: FeatureFlagsState = {
  newDurInputsModalEnabled: false,
  hideConsentOnPv1: false,
  hideRefillsWrittenField: false,
  highlightPv1Differences: false,
  multiPatientOrder: false,
  shouldApplyInterfaceImprovements: false,
  showCopayCollected: false,
}

type UsePV1FlagsProps = {
  customerLegacyId?: number
  userEmail?: string
}

/**
 * usePV1FeatureFlags centralizes LaunchDarkly identification and flag retrieval.
 * It listens for changes and updates a single state object with individual flag values.
 *
 * @param customerLegacyId - The legacy ID of the customer
 * @param userEmail - The email of the user
 * @returns The current feature flags state
 */
export function usePV1FeatureFlags({ customerLegacyId, userEmail }: UsePV1FlagsProps): FeatureFlagsState {
  const defaultCustomerLegacyId = '0' // Truepill
  const { client: ldClient } = useClient()
  const [flags, setFlags] = useState<FeatureFlagsState>(initialFlagsState)

  const {
    newDurInputsModalEnabled,
    hideConsentOnPv1,
    hideRefillsWrittenField,
    highlightPv1Differences,
    multiPatientOrder,
    shouldApplyInterfaceImprovements,
    showCopayCollected,
  } = useFlags({
    newDurInputsModalEnabled: LaunchDarkly.FeatureFlags.TEMP_DUR_TOKEN_MATCHING,
    hideConsentOnPv1: LaunchDarkly.FeatureFlags.TEMP_HIDE_CONSENT_ON_PV1,
    hideRefillsWrittenField: LaunchDarkly.FeatureFlags.TEMP_HIDE_REFILLS_WRITEEN_FIELD_RX_REVIEW,
    highlightPv1Differences: LaunchDarkly.FeatureFlags.TEMP_HIGHLIGHT_PV1_DIFFERENCES,
    multiPatientOrder: LaunchDarkly.FeatureFlags.TEMP_MULTI_PATIENT_ORDER,
    shouldApplyInterfaceImprovements: LaunchDarkly.FeatureFlags.PV1_INTERFACE_CONSISTENCY,
    showCopayCollected: LaunchDarkly.FeatureFlags.DISPLAY_COLLECTED_COPAY_IN_FRONTEND,
  })

  useEffect(() => {
    if (!ldClient) return

    const updateFlags = () => {
      setFlags({
        newDurInputsModalEnabled,
        hideConsentOnPv1,
        hideRefillsWrittenField,
        highlightPv1Differences,
        multiPatientOrder,
        shouldApplyInterfaceImprovements,
        showCopayCollected,
      })
    }

    // Identify the user context and use the callback to update the flags.
    ldClient.identify(
      {
        key: customerLegacyId?.toString() ?? defaultCustomerLegacyId,
        email: userEmail,
      },
      undefined,
      updateFlags,
    )
  }, [
    ldClient,
    customerLegacyId,
    userEmail,
    newDurInputsModalEnabled,
    hideConsentOnPv1,
    hideRefillsWrittenField,
    highlightPv1Differences,
    multiPatientOrder,
    shouldApplyInterfaceImprovements,
    showCopayCollected,
  ])

  return flags
}
