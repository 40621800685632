import { Link, useLocation, useHistory } from '@truepill/tpos-react-router'
import { Text } from 'grommet'
import { useGetProductionEnvironment } from 'hooks/useGetProductionEnvironment'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import styled, { css } from 'styled-components'
import { primaryColor, primaryBackgroundColor, bodySecondaryColor, accentPink } from 'styles/styleVariables'

interface NavButtonProps {
  path: string
  icon: JSX.Element
  title: string
  hotKey: string
  target?: string
  excludedQueryParams?: string[]
}

const NavButton = (props: NavButtonProps): JSX.Element => {
  const { title, path, icon, hotKey, target, excludedQueryParams } = props
  const { pathname, search } = useLocation()
  // Nav Buttons match on the top level of the path
  const matches = pathname.match(/(\/\w+)/)
  const isCurrentPage = !!matches && matches[0] === path
  const history = useHistory()
  const isProductionEnvironment = useGetProductionEnvironment()

  const filteredSearch = (() => {
    if (!excludedQueryParams?.length || !search) return search

    const searchParams = new URLSearchParams(search)
    excludedQueryParams.forEach(param => searchParams.delete(param))

    const filteredParamsString = searchParams.toString()
    return filteredParamsString ? `?${filteredParamsString}` : ''
  })()

  useHotKey(hotKey, HotKeyLevel.global, () => {
    history.push(path + filteredSearch)
  })

  return (
    <StyledLink isCurrentPage={isCurrentPage} isProductionEnvironment={isProductionEnvironment}>
      <Link to={{ pathname: path, search: filteredSearch }} target={target}>
        {icon}
        <StyledText isCurrentPage={isCurrentPage} isProductionEnvironment={isProductionEnvironment}>
          {title}
        </StyledText>
      </Link>
    </StyledLink>
  )
}

const StyledText = styled(Text)<{ isCurrentPage: boolean; isProductionEnvironment: boolean }>`
  font-weight: 500;
  margin-left: 0.5rem;
  color: ${({ isCurrentPage, isProductionEnvironment }) =>
    !isProductionEnvironment ? primaryBackgroundColor : isCurrentPage ? primaryColor : bodySecondaryColor};
`

const StyledLink = styled.li<{ isCurrentPage: boolean; isProductionEnvironment: boolean }>`
  display: flex;
  > a {
    padding: 0.625rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    ${({ isProductionEnvironment, isCurrentPage }) =>
      isProductionEnvironment
        ? isCurrentPage
          ? HighlightedProd
          : NormalProd
        : isCurrentPage
        ? HighlightedTest
        : NormalTest};
  }
`

const HighlightedTest = css`
  > svg {
    fill: ${primaryBackgroundColor};
  }
  border-bottom: 0.25rem solid ${primaryBackgroundColor};
`
const NormalTest = css`
  > svg {
    fill: ${primaryBackgroundColor};
  }
  border-bottom: 0.25rem solid ${accentPink};
`

const HighlightedProd = css`
  > svg {
    fill: ${primaryColor};
  }
  border-bottom: 0.25rem solid ${primaryColor};
`

const NormalProd = css`
  > svg {
    fill: ${bodySecondaryColor};
  }
  border-bottom: 0.25rem solid ${primaryBackgroundColor};
`

export default NavButton
