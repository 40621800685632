export enum ABRatedCodes {
  AA = 'AA',
  AB = 'AB',
  AN = 'AN',
  AO = 'AO',
  AP = 'AP',
  AT = 'AT',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
}

export interface NdcSubstitutionsFilters {
  allowDaw1Substitutions?: boolean
  filterActiveSubstitutions?: boolean
  filterNonGenerics?: boolean
}

export const NDC_ACTIVE_STATUS_FLAG = 'A'
export const NDC_INACTIVE_STATUS_FLAG = 'I'

export interface MedispanNdcType {
  ndc: string
  drugDescriptorId: number
  teCode: string
  deaCode?: string
  desiCode?: string
  rxOtcIndicatorCode?: string
  gppc: string
  oldId?: string
  newId?: string
  repackageCode?: string
  format: string
  thirdPartyRestrictionCode?: string
  kbDrugCode: number
  kdcFlag: string
  mediSpanLabelerId: number
  multiSourceCode?: string
  nameTypeCode?: string
  itemStatusFlag: string
  innerpackCode: string
  clinicPackCode?: string
  ppgIndicatorCode?: string
  hfpgIndicatorCode?: string
  dispensingUnitCode?: string
  dollarRankCode?: string
  rxRankCode?: string
  storageConditionCode: string
  limitedDistributionCode?: string
  oldEffectiveDate?: string
  newEffectiveDate?: string
  nextSmallerNdcSuffixNumber?: string
  nextLargerNdcSuffixNumber?: string
  transactionCode?: string
}
