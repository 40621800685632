import { Button } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import { PrinterPurpose } from '@truepill/tpos-types'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import { PRINT_PICK_SLIPS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import PrintPickSlipModal from 'modals/PrintPickSlipModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePrintProvider } from 'providers/PrintProvider'
import styled, { css } from 'styled-components'
import { capsulePrimaryColorDark, capsulePrimaryColorLight, capsuleDarkBlue } from 'styles/styleVariables'

type PrintPickSlipButtonProps = { orderId: string }

const PrintPickSlipButton = ({ orderId }: PrintPickSlipButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const { selectedPrinter, getSavedPrinterId } = usePrintProvider(PrinterPurpose.ShippingLabel)
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast(true)

  const [printLabel] = useMutation(PRINT_PICK_SLIPS, {
    onCompleted: data => {
      if (data.printPickSlips.status) {
        showSuccessToast('Print pick slips request sent.')
      } else {
        showErrorToast(`Print error: ${data.printPickSlips.error}`)
      }
    },
    onError: error => {
      showErrorToast(`Network error: ${error.message}`)
    },
  })

  const handlePrint = () => {
    const savedPrinterId = getSavedPrinterId(PrinterPurpose.ShippingLabel)
    if (selectedPrinter && savedPrinterId) {
      printLabel({
        variables: {
          orderIds: [orderId],
          printerUrl: selectedPrinter.GCPAddress,
        },
      })
    } else {
      showModal(() => <PrintPickSlipModal orderIDs={[orderId]} />)
    }
  }

  return (
    <ButtonWrapper>
      <Button size="xs" variant="primary-outline" onClick={handlePrint}>
        {<PrintIcon />} Print pick slip
      </Button>
    </ButtonWrapper>
  )
}

const StyledButton = css`
  border-radius: 8px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  svg {
    fill: ${capsulePrimaryColorDark};
    margin-right: 0.25rem;
    min-width: 16px;
  }
  :hover {
    svg {
      fill: ${capsulePrimaryColorLight};
    }
  }
  :disabled {
    svg {
      fill: ${capsuleDarkBlue};
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.625rem;
  ul {
    width: auto;
    right: 0;
    white-space: nowrap;
  }
  > button {
    ${StyledButton}
  }
`

export default PrintPickSlipButton
