import { useMemo } from 'react'
import type { CSS } from '@truepill/capsule-utils'
import { Checkbox, Select } from '@truepill/react-capsule'

export interface MultiSelectOption<T extends string> {
  label: string
  value: T
  disabled?: boolean
  selectAll?: boolean
}

interface MultiSelectProps<T extends string> {
  value: T[]
  onChange: (value: string[]) => void
  options: MultiSelectOption<T>[]
  label?: string
  placeholder?: string
  css?: CSS
  disabled?: boolean
}

const MultiSelect = <T extends string>({
  value = [],
  onChange,
  options,
  label = '',
  placeholder,
  disabled,
  css,
}: MultiSelectProps<T>) => {
  const handleChange = (optionValue: string, checked: boolean, isSelectAll: boolean) => {
    if (isSelectAll) {
      onChange(checked ? options.map(opt => opt.value) : [])
    } else {
      let newValue = checked ? [...value, optionValue] : value.filter(v => v !== optionValue)
      if (!checked && value.some(v => options.find(opt => opt.value === v)?.selectAll)) {
        newValue = newValue.filter(v => !options.find(opt => opt.value === v)?.selectAll)
      }
      const allSelected = options.every(opt => opt.selectAll || newValue.includes(opt.value))
      onChange(allSelected ? options.map(opt => opt.value) : newValue)
    }
  }
  const selectAllValue = useMemo(() => (options.find(opt => opt.selectAll)?.value ?? '') as T, [options])
  const valueLabel = useMemo(
    () => value.map(v => options.find(opt => opt.value === v)?.label).join(',') as any,
    [options, value],
  )
  const renderedValue = useMemo(
    () => (options.some(opt => opt.selectAll && value.includes(opt.value)) ? selectAllValue : valueLabel),
    [options, value, selectAllValue],
  )

  return (
    <Select
      label={label}
      css={{
        ul: { maxHeight: '21rem' },
        ...css,
      }}
      value={renderedValue}
      onChange={e => {
        // Because the elements are checkboxes which trigger an action, we do not need to do anything here.
        // Do not close the dropdown everytime a check is selected
        e.preventDefault()
      }}
      options={options}
      placeholder={placeholder}
      selectedKey="label"
      disabled={disabled}
      optionComponent={({ option }) => (
        <Checkbox
          label={option.label}
          checked={option.selectAll ? value.includes(selectAllValue) : value.includes(option.value)}
          onCheckedChange={checked => handleChange(option.value, Boolean(checked), Boolean(option.selectAll))}
        />
      )}
    />
  )
}

export default MultiSelect
