import { useState, useEffect, useCallback } from 'react'
import type { FfsInventoryInfo } from '@truepill/tpos-types'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { MemoizedSubstitutionSelect as SubstitutionSelect } from 'components/SubstitutionSelect'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useUpdateDispensedMedication from 'hooks/useUpdateDispensedMedication'
import WrappedClaimsSuccessModal from 'modals/ClaimsSuccessModal'
import SimpleModal from 'modals/SimpleModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, borderColor } from 'styles/styleVariables'
import type { Fill, Order, Prescription, RXFillRequest, NdcSubstitutionPackage } from 'types'
import { isInsuranceFill, stripTypename } from 'utils'
import { isABRatedSubstitution } from 'utils/ndc'

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
`

const InputContainer = styled.div`
  width: 24rem;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  > textarea {
    padding-top: 0.3125rem;
    height: 7rem;
    margin-top: 0.625rem;
    border: 0.125rem solid ${borderColor};
  }
`

type ChangeDispensedItemModalProps = {
  order: Order
  fill: Fill
  prescription: Prescription
  rxFillRequest: RXFillRequest
  ndcSubstitutionPackage?: NdcSubstitutionPackage
}

const ChangeDispensedItemModal = ({
  order,
  fill,
  prescription,
  rxFillRequest,
}: ChangeDispensedItemModalProps): JSX.Element => {
  const [isAbRated, setIsAbRated] = useState<boolean>(false)
  const [ndc, setNdc] = useState(fill.dispensed?.ndc)
  const [ndcInventoryGroupId, setNdcInventoryGroupId] = useState<string | undefined>(
    fill.dispensed.inventoryGroupId?.toString(),
  )
  const [ffsInventoryInfo, setFfsInventoryInfo] = useState<FfsInventoryInfo | undefined>(
    fill.dispensed.ffsInventoryInfo && Object.keys(fill.dispensed.ffsInventoryInfo).length > 0
      ? {
          ...stripTypename(fill.dispensed.ffsInventoryInfo),
        }
      : undefined,
  )
  const isInsurance = isInsuranceFill(rxFillRequest)
  const { dismissModal } = useModalContext()
  const showSuccess = useSuccessToast()

  const [updateDispensedMedication] = useUpdateDispensedMedication(isInsurance, data => {
    dismissModal()
    const { updateDispensed: fill } = data
    if (isInsurance) {
      showModal(() => (
        <WrappedClaimsSuccessModal
          item={order}
          fill={fill}
          itemFill={rxFillRequest}
          updatedDispensed={{
            ndc: fill.dispensed.ndc,
            name: fill.dispensed.name,
          }}
        />
      ))
    } else {
      showSuccess(`Updated dispensed medication item.`)
    }
  })

  const handleSelectSubstitution = useCallback(
    ({ ndc, teCode, inventoryGroup, ffsInventoryInfo }, wasManuallySearched) => {
      setNdc(ndc)
      setNdcInventoryGroupId(inventoryGroup?._id.toString())
      setIsAbRated(isABRatedSubstitution(teCode) && !wasManuallySearched)
      setFfsInventoryInfo(
        ffsInventoryInfo && Object.keys(ffsInventoryInfo).length > 0
          ? stripTypename({ ...ffsInventoryInfo })
          : undefined,
      )
    },
    [],
  )

  useEffect(
    () => {
      if (fill.dispensed.ndc === ndc) {
        return
      }
      setNdc(fill.dispensed.ndc)
    },
    // This intentionally only updates on fill.dispensed.ndc.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fill.dispensed.ndc],
  )
  const { showModal } = useModalContext()
  const canBeSubstituted = [0, 1].includes(prescription.daw)

  return (
    <ModalWrapper id="ChangeDispensedItemModal">
      <ModalHeader>
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Change dispensed item</h2>
      </ModalHeader>
      <InputContainer>
        {canBeSubstituted ? (
          <SubstitutionSelect
            preventScrolling={true}
            prescription={prescription}
            fill={fill}
            dispensedNdc={ndc}
            orderId={order._id}
            onSelect={handleSelectSubstitution}
          />
        ) : (
          <>Prescription DAW is not 0 or 1, so dispensed item can&apos;t be changed.</>
        )}
      </InputContainer>
      <ButtonsContainer>
        <CancelButton label="Cancel" onClick={dismissModal} />
        <SaveButton
          disabled={!canBeSubstituted || ndc === fill.dispensed.ndc}
          isModal
          label="Submit"
          onClick={() => {
            !isAbRated
              ? showModal(() => (
                  <SimpleModal
                    title={'Are you sure?'}
                    confirmButtonLabel={'Update'}
                    cancelButtonLabel={'Cancel'}
                    confirmationCallback={() => {
                      updateDispensedMedication({
                        variables: {
                          itemId: order._id,
                          fillId: fill._id,
                          ndc,
                          inventoryGroupId: ndcInventoryGroupId,
                          ffsInventoryInfo: ffsInventoryInfo,
                        },
                      })
                    }}
                  >
                    <p>
                      {
                        'The dispensed medication you have selected is not therapeutically equivalent to the prescribed NDC. Do you want to proceed?'
                      }
                    </p>
                  </SimpleModal>
                ))
              : updateDispensedMedication({
                  variables: {
                    itemId: order._id,
                    fillId: fill._id,
                    ndc,
                    inventoryGroupId: ndcInventoryGroupId,
                    ffsInventoryInfo: ffsInventoryInfo,
                  },
                })
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}
export default ChangeDispensedItemModal
