import { useCallback } from 'react'
import { Link } from '@truepill/tpos-react-router'
import { PriorAuthorizationOrigin } from '@truepill/tpos-types'
import LoadingSpinner from 'components/Loading'
import { FilledHeadingStyle, NoResultsEntry } from 'components/PageStructure'
import Lozenge from 'components/Tiles/Lozenge'
import { useLazyFulfillmentPriorAuthorizations } from 'hooks/navigation/useFulfillmentPriorAuthorizations'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import usePaginationManager from 'hooks/usePaginationManager'
import { goToFulfillmentCopay, goToFulfillmentOrder, goToPriorAuthorization } from 'routes'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { contrastColor, contrastBackgroundColor, primaryColorLight, primaryColor } from 'styles/styleVariables'
import type { Patient, PriorAuthorization } from 'types'
import { formatCreatedDate } from 'utils'
import Pagination from './Pagination'

type PriorAuthorizationHistoryProps = {
  patientId?: Patient['_id']
  rxNumber?: number
  searchTerm?: string
  showPagination?: boolean
}

const PriorAuthorizationHistory = (props: PriorAuthorizationHistoryProps): JSX.Element => {
  const { patientId, rxNumber, searchTerm = '', showPagination = true } = props

  const [getPriorAuthorizations, { data, loading, error }] = useLazyFulfillmentPriorAuthorizations()

  const fetchMore = useCallback(
    (page: number, limit: number) => {
      getPriorAuthorizations({
        variables:
          !showPagination || !!searchTerm
            ? { patientId, rxNumber }
            : { patientId, rxNumber, pageSize: limit, pageNumber: page },
      })
    },
    [getPriorAuthorizations, showPagination, searchTerm, patientId, rxNumber],
  )

  const {
    items: priorAuthorizations,
    currentPage,
    setPage,
    totalRecords,
    totalPages,
    pageSize,
    setLimit,
  } = usePaginationManager(fetchMore, {
    totalRecords: data?.getFulfillmentPriorAuthorizations.totalRecords ?? 0,
    items: data?.getFulfillmentPriorAuthorizations.priorAuthorizations ?? [],
  })

  if (loading) {
    return (
      <MainLoadingContainer>
        <TitleRow />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </MainLoadingContainer>
    )
  }

  if (error) {
    return <p>Failed to load Prior Authorization History {JSON.stringify(error)} </p>
  }

  const filteredPAs = (priorAuthorizations ?? []).filter(pa => {
    const cmmKey = pa.cmmKey?.includes(searchTerm.toLowerCase()) || false
    const rxNumber = pa.rxNumber.toString() === searchTerm
    const medication = (pa.medicationName ?? '').toLowerCase().includes(searchTerm.toLowerCase())
    return cmmKey || rxNumber || medication
  })

  return (
    <PriorAuthorizationHistoryContainer>
      <TitleRow />
      {filteredPAs.map(pa => (
        <PriorAuthorizationHistoryEntry key={pa._id} pa={pa} />
      ))}
      {priorAuthorizations.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
      {showPagination && !searchTerm && (
        <Pagination
          onChangePage={setPage}
          totalRecords={totalRecords}
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={setLimit}
        />
      )}
    </PriorAuthorizationHistoryContainer>
  )
}

const TitleRow = (): JSX.Element => {
  return (
    <TitlePriorAuthorizationHistoryRow>
      <CMMKey>CMM Key</CMMKey>
      <RXNumber>Rx Number</RXNumber>
      <LocationId>Location Id</LocationId>
      <Medication>Medication</Medication>
      <Status>Status</Status>
      <InitiatedBy>Initiated by</InitiatedBy>
      <SubmittedDate>Submitted Date</SubmittedDate>
      <StartDate>Start Date</StartDate>
    </TitlePriorAuthorizationHistoryRow>
  )
}

type PriorAuthorizationHistoryEntryProps = { pa: PriorAuthorization }

const PriorAuthorizationHistoryEntry = ({ pa }: PriorAuthorizationHistoryEntryProps): JSX.Element => {
  const fulfillmentQueue = useFulfillmentQueue()

  const copayRequestId = pa.initiatedBy?.copayRequestId?.toString() || ''
  const orderId = pa.initiatedBy?.orderId?.toString() || ''

  return (
    <StyledLink to={goToPriorAuthorization({ priorAuthorizationId: pa._id })}>
      <PriorAuthorizationHistoryRow>
        <CMMKey>{pa.cmmKey}</CMMKey>
        <RXNumber>{pa.rxNumber}</RXNumber>
        <LocationId>{pa.location?.legacyId}</LocationId>
        <Medication>{pa.medicationName}</Medication>
        <Status>{pa.status}</Status>
        <InitiatedBy>
          {pa.initiatedBy.orderId ? (
            <StyledAnchor
              href={goToFulfillmentOrder({
                fulfillmentQueueName: fulfillmentQueue?.name,
                orderId,
              })}
              target="_blank"
              onClick={ev => ev.stopPropagation()}
            >
              Order
            </StyledAnchor>
          ) : pa.initiatedBy.origin === PriorAuthorizationOrigin.Copay ? (
            <StyledAnchor
              href={goToFulfillmentCopay({
                fulfillmentQueueName: fulfillmentQueue?.name,
                copayRequestId,
              })}
              target="_blank"
              onClick={ev => ev.stopPropagation()}
            >
              Copay
            </StyledAnchor>
          ) : (
            <>{pa.initiatedBy.origin ?? 'N/A'}</>
          )}
        </InitiatedBy>
        <SubmittedDate>{pa.submittedAt ? formatCreatedDate(pa.submittedAt).date : 'N/A'}</SubmittedDate>
        <StartDate>{pa.startedAt ? formatCreatedDate(pa.startedAt).date : 'N/A'}</StartDate>
      </PriorAuthorizationHistoryRow>
    </StyledLink>
  )
}

const PriorAuthorizationHistoryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
`

const StyledLink = styled(Link)`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  padding: 0.1rem 0.625rem;
  :nth-of-type(1n) {
    padding-bottom: 0.625rem;
  }
  :nth-of-type(1n - 1) {
    padding-top: 0.625rem;
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`

const PriorAuthorizationHistoryRow = styled.ul`
  display: grid;
  grid-template-rows: [content] auto [fillHistory] auto;
  grid-template-columns:
    [CMMKey] minmax(11rem, 11rem)
    [LocationId] minmax(7rem, 7rem)
    [InitiatedBy] minmax(8rem, 8rem)
    [SubmittedDate] minmax(11rem, 11rem)
    [StartDate] minmax(11rem, 11rem)
    [RXNumber] minmax(8rem, 8rem)
    [medication] minmax(12rem, 1fr)
    [status] minmax(7rem, 9rem);
`

const TitlePriorAuthorizationHistoryRow = styled(PriorAuthorizationHistoryRow)`
  ${FilledHeadingStyle}
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const HistoryCell = styled.li`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const CMMKey = styled(HistoryCell)`
  grid-column: CMMKey;
`

const LocationId = styled(HistoryCell)`
  grid-column: LocationId;
`
const InitiatedBy = styled(HistoryCell)`
  grid-column: InitiatedBy;
`

const SubmittedDate = styled(HistoryCell)`
  grid-column: SubmittedDate;
`

const StartDate = styled(HistoryCell)`
  grid-column: StartDate;
`

const StyledAnchor = styled.a`
  color: ${primaryColor};
  grid-column: InitiatedBy;
  display: block;
  font-weight: bolder;
  &:visited {
    color: ${primaryColor};
    font-weight: bolder;
  }
`

const RXNumber = styled(HistoryCell)`
  grid-column: RXNumber;
`

const Medication = styled(HistoryCell)`
  grid-column: medication;
`

const Status = styled(HistoryCell)`
  grid-column: status;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${Lozenge}:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  ${Lozenge}:last-child {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 1640px) {
    flex-wrap: nowrap;
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 50px;
  }
`

const MainLoadingContainer = styled.div`
  width: 100%;
`

export default PriorAuthorizationHistory
