import { useState } from 'react'
import { Select } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import type { Pod, PodDrug } from '@truepill/tpos-types'
import MultiSelect, { type MultiSelectOption } from 'components/MultiSelect'
import { GET_ALL_PODS } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'

type BaseProps = {
  value?: string
  disabled?: boolean
}

type SingleSelectProps = BaseProps & {
  multiSelect?: false
  onSelect: (podName?: string) => void
}

type MultiSelectProps = BaseProps & {
  multiSelect: true
  onSelect: (podName: string[]) => void
}

type PodSelectProps = SingleSelectProps | MultiSelectProps

const PodSelect = (props: PodSelectProps): JSX.Element => {
  const { onSelect, value, disabled, multiSelect } = props
  const [allOptionsChecked, setAllOptionsChecked] = useState(false)
  const { currentLocation } = usePlusClient()
  const { data, loading, error } = useQuery(GET_ALL_PODS, {
    variables: { locationId: currentLocation?.queryMap?.locationId },
  })

  if (loading || error) {
    return <></>
  }

  const pods: Pod[] = data?.getAllPods
  // won't show pod filter unless there are pods to filter by
  if (!pods.length) {
    return <></>
  }

  const multiSelectOptions: MultiSelectOption<string>[] = [
    { label: 'Select All', value: 'All', selectAll: true },
    ...pods.map((p: Pod) => ({ label: p.podName, value: p.podName })),
  ]
  const singleSelectOptions = multiSelectOptions.slice(1)

  const selectedValue = typeof value === 'string' ? { label: value, value } : undefined
  const multiSelectValues =
    value?.includes('All') || allOptionsChecked
      ? multiSelectOptions.map(opt => opt.value)
      : Array.isArray(value)
      ? value
      : value?.split(',') || []

  return multiSelect ? (
    <MultiSelect
      label="Pod"
      value={multiSelectValues}
      placeholder="Select pods…"
      options={multiSelectOptions}
      onChange={values => {
        currentLocation.queryMap.podName = values
        if (values.includes('All')) {
          onSelect(['All'])
          setAllOptionsChecked(true)
        } else {
          onSelect(values)
          setAllOptionsChecked(false)
        }
      }}
    />
  ) : (
    <Select
      label="Pod"
      value={selectedValue}
      onChange={option => {
        if (option?.value !== selectedValue?.value && option?.value) {
          currentLocation.queryMap.podName = option.value
          onSelect(option?.value as string)
        } else {
          currentLocation.queryMap.podName = undefined
          onSelect(undefined)
        }
      }}
      options={singleSelectOptions}
      placeholder="Select pod"
      selectedKey="value"
      disabled={disabled}
    />
  )
}

export default PodSelect
