import { usePlusClient } from 'providers/VisionRouter'
import type { GenericEvents } from 'services/analytics/events/genericEvents'
import type { PV1Events } from 'services/analytics/events/pv1Events'
import MixpanelService from '../services/analytics/mixpanel'

const useAnalytics = () => {
  const {
    tokenContext: { firstName, lastName, roles, id },
  } = usePlusClient()

  if (!MixpanelService.isIdentified() && id) {
    MixpanelService.identify({
      userId: id,
      name: `${firstName} ${lastName}`,
      roles: roles ? Object.keys(roles) : [],
    })
  }

  const trackEvent = (event: keyof typeof GenericEvents, properties?: { [key: string]: any }) => {
    MixpanelService.track(event, {
      ...properties,
    })
  }

  const trackPV1Event = (event: keyof typeof PV1Events, properties?: { fillId?: string; [key: string]: any }) => {
    MixpanelService.track(event, {
      ...properties,
    })
  }

  return { trackEvent, trackPV1Event }
}

export default useAnalytics
