import { useEffect, useRef } from 'react'
import { LaunchDarkly, PriorAuthorizationStatus } from '@truepill/tpos-types'
import Paging from 'components/Paging'
import type { GetFulfillmentPriorAuthorizationParams } from 'hooks/navigation/useFulfillmentPriorAuthorizations'
import useFulfillmentPriorAuthorizations from 'hooks/navigation/useFulfillmentPriorAuthorizations'
import useErrorToast from 'hooks/toast/useErrorToast'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import isEqual from 'lodash.isequal'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import type { PriorAuthorization } from 'types'
import PrescriptionQueue from './PrescriptionQueue'

type ExpandedQueueSectionProps = { search: string; isCopay?: boolean }

const ExpandedQueueSection = ({ search, isCopay = false }: ExpandedQueueSectionProps): JSX.Element => {
  const {
    routeToMergedQuery,
    currentLocation,
    QueryToolkit: { StringBoolean },
  } = usePlusClient()
  const filtersResetPagination = useFlag(LaunchDarkly.FeatureFlags.TEMP_FILTERS_RESET_PAGINATION)
  const sortNewestToOldest = useFlag(LaunchDarkly.FeatureFlags.TEMP_PRIOR_AUTHORIZATION_QUEUE_NEWEST_TO_OLDEST)

  const showErrorToast = useErrorToast()
  const fulfillmentQueue = useFulfillmentQueue()
  const lastQuery = useRef<GetFulfillmentPriorAuthorizationParams | undefined>(undefined)

  const pageSize = currentLocation.queryMap.pageSize ? parseInt(currentLocation.queryMap.pageSize, 10) : 10

  const pagingVariables = {
    pageNumber: Number(currentLocation.queryMap?.page) || 0,
    pageSize: pageSize,
  }

  const queryMap = currentLocation?.queryMap || {}

  let statuses = queryMap.priorAuthorizationStatuses
    ? queryMap.priorAuthorizationStatuses
    : !isCopay
    ? [PriorAuthorizationStatus.ManualStart]
    : Object.values(PriorAuthorizationStatus).filter(
        value => ![PriorAuthorizationStatus.Approved, PriorAuthorizationStatus.Denied].includes(value),
      )

  if (queryMap.paSubQueueStatus) {
    //translate statuses
    if (queryMap.paSubQueueStatus !== 'All') {
      statuses = [queryMap.paSubQueueStatus]
    } else {
      statuses = undefined
    }
  }

  const getPriorAuthorizationVariables: GetFulfillmentPriorAuthorizationParams = {
    ...(fulfillmentQueue?.filters ?? {}),

    // When we are in the order queue we want to show all PAs, no order/copay initiated-by filter (JR-21006)
    ...{ isOrder: false },
    ...{ isCopay },
    // --------------------------------------------

    ...(queryMap.manualPriorAuthorizations && { isManual: !!StringBoolean(queryMap.manualPriorAuthorizations) }),
    ...(!queryMap.locationIds && queryMap.locationId && { locationId: queryMap.locationId }),
    ...(queryMap.customerIds && { customerIds: queryMap.customerIds }),
    ...(statuses && { statuses }),
    ...(search && { stringSearch: search }),
  }

  // if the filter values have changed, reset the page to zero
  if (
    filtersResetPagination &&
    lastQuery.current !== undefined &&
    !isEqual(getPriorAuthorizationVariables, lastQuery.current)
  ) {
    // query for page number 0
    pagingVariables.pageNumber = 0
    // set the url query string
    routeToMergedQuery({ page: 0 })
  }

  // store the last filters
  lastQuery.current = getPriorAuthorizationVariables

  const priorAuthorizationVariables: GetFulfillmentPriorAuthorizationParams = {
    ...getPriorAuthorizationVariables,
    ...pagingVariables,
    sort: sortNewestToOldest ? -1 : 1,
  }

  const { data, loading, error } = useFulfillmentPriorAuthorizations(priorAuthorizationVariables)

  useEffect(() => {
    if (error) {
      showErrorToast(error?.message)
    }
  }, [error, showErrorToast])

  const priorAuthorizations: PriorAuthorization[] = data?.getFulfillmentPriorAuthorizations?.priorAuthorizations || []

  const totalPriorAuthorizations = data?.getFulfillmentPriorAuthorizations?.totalRecords ?? 0
  const currentPage = data?.getFulfillmentPriorAuthorizations?.currentPage ?? 0
  const totalPages = Math.ceil(totalPriorAuthorizations / pageSize)

  return (
    <SingleQueue>
      <PrescriptionQueue
        items={priorAuthorizations}
        totalItems={totalPriorAuthorizations}
        loading={loading}
        queue={fulfillmentQueue as FulfillmentQueue}
        selected
        locationId={queryMap.locationId}
      />

      {!loading && (
        <Paging
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={pageNumber => {
            routeToMergedQuery({ page: pageNumber })
          }}
          totalRecords={totalPriorAuthorizations}
          pageSize={pageSize}
        />
      )}
    </SingleQueue>
  )
}

export default ExpandedQueueSection

const SingleQueue = styled.div`
  background-color: white;
  grid-row: content;
  grid-column: fills;
  margin-left: 1.25rem;
  height: calc(100vh - 13rem);
  margin-right: 1.875rem;
  position: relative;
`
