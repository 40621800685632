import RXScanImage from 'components/RXScanImage'
import {
  ListRowLabel,
  ListRowValue,
  SmallPaddingBlock,
  RaisedAnchor,
  StyledRXImageCell,
  RXListRowSingle,
  ImageFilledHeader,
} from 'components/RXTable'
import styled from 'styled-components'
import type { Item } from 'types'
import { InsuranceDesignationType } from 'types'

type ReviewPayerProps = {
  item: Item
  insuranceImages: { insuranceOptionId: string; url: string }[]
}

const DESIGNATION_TYPE = [
  InsuranceDesignationType.Primary,
  InsuranceDesignationType.Secondary,
  InsuranceDesignationType.Tertiary,
]

const ReviewPayer = ({ item, insuranceImages = [] }: ReviewPayerProps): JSX.Element => {
  const itemInsurances = item.insurances

  return (
    <>
      <TitleRow>
        <SmallPaddingBlock />
        <RXImageInsuranceFilledHeader>
          <RaisedAnchor id={`ReviewPayer`} />
          Payer
        </RXImageInsuranceFilledHeader>
      </TitleRow>
      {itemInsurances.map((itemInsurance, index) => {
        const insuranceImage = insuranceImages.find(
          (i: { insuranceOptionId: string }) =>
            i.insuranceOptionId.toString() === itemInsurance.orderInsuranceOptionId?.toString() ||
            i.insuranceOptionId.toString() === itemInsurance._id?.toString(),
        )?.url

        return (
          <div key={index}>
            <Designation>{DESIGNATION_TYPE[index] ?? 'Other'}</Designation>
            <RXListRowSingle>
              <StyledRXImageCell closeOffTopBorder>
                <ListRowLabel>Cardholder:</ListRowLabel>
                <ListRowValue>{itemInsurance.cardholderId}</ListRowValue>
              </StyledRXImageCell>
            </RXListRowSingle>
            <RXListRowSingle>
              <StyledRXImageCell>
                <ListRowLabel>BIN:</ListRowLabel>
                <ListRowValue>{itemInsurance.bin}</ListRowValue>
              </StyledRXImageCell>
            </RXListRowSingle>
            <RXListRowSingle>
              <StyledRXImageCell>
                <ListRowLabel>PCN:</ListRowLabel>
                <ListRowValue>{itemInsurance.pcn}</ListRowValue>
              </StyledRXImageCell>
            </RXListRowSingle>
            <RXListRowSingle>
              <StyledRXImageCell closeOffBottomBorder>
                <ListRowLabel>Group:</ListRowLabel>
                <ListRowValue>{itemInsurance.group}</ListRowValue>
              </StyledRXImageCell>
            </RXListRowSingle>
            {insuranceImage && <RXScanImage src={insuranceImage} inline={true} />}
            <SmallPaddingBlock />
          </div>
        )
      })}
    </>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const Designation = styled.div`
  font-weight: 500;
`
const TitleRow = styled.div``

export default ReviewPayer
