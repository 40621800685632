import { StrictMode } from 'react'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { datadogRum } from '@datadog/browser-rum'
import config from 'config'
import { render } from 'react-dom'
import App from './App'
import MixpanelService from './services/analytics/mixpanel'
import * as serviceWorker from './serviceWorkerRegistration'
import 'utils/fakeBarcodeScanner'

// Adds Apollo messages instead of links to view messages (potentially leaking PII/PHI) JR-17455
loadDevMessages()
loadErrorMessages()
MixpanelService.initialize()

if (config.dataDog.clientToken) {
  const ddHigherEnvUrlPath = config.auth0.callbackUrl?.split('/login')?.[0] || ''
  const disabledHosts = ['launchdarkly.com', 'docuprotect.s3.amazonaws.com']

  datadogRum.init({
    applicationId: config.dataDog.applicationId,
    clientToken: config.dataDog.clientToken,
    env: config.dataDog.env,
    service: config.dataDog.service,
    site: 'us5.datadoghq.com',
    version: config.dataDog.version,
    allowedTracingUrls: [
      {
        match: config.dataDog.env === 'development' ? 'http://localhost:3000' : ddHigherEnvUrlPath,
        propagatorTypes: ['tracecontext'],
      },
    ],
    compressIntakeRequests: true,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    beforeSend: event => {
      const eventResource = event.resource as { url?: string }
      if (eventResource?.url) {
        return !disabledHosts.some(host => eventResource.url?.includes(host))
      }
      return true
    },
  })
}

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
