import { useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import AutoCompleteOption from 'components/MedicationAutocompleteOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { SEARCH_OTC_ORDER_MEDICATIONS } from 'gql'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { MedicationSuggestionItem, OrderMedicationSearchResult } from 'types'

type OrderOTCSearchProps = {
  isVisible?: boolean
  hotkey?: string
  label?: string
  isModal?: boolean
}

const OrderOTCSearch = ({
  isVisible,
  hotkey = 'o',
  isModal,
  label = 'OTC medications',
}: OrderOTCSearchProps): JSX.Element => {
  const shouldNotUseSimplifiedMedicationSearch = !useFlag(LaunchDarkly.FeatureFlags.USE_SIMPLIFIED_MEDICATION_SEARCH)
  const queue = useFulfillmentQueue() as FulfillmentQueue
  const { currentLocation, routeToMergedQuery } = usePlusClient()
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')

  const { data, loading, error } = useQuery(SEARCH_OTC_ORDER_MEDICATIONS, {
    skip: debouncedSearchTerm.length < 3,
    variables: {
      name: debouncedSearchTerm.length >= 3 ? debouncedSearchTerm : undefined,
      orderStages: queue?.orderStages || [],
      // fillStages: queue?.fillStages || [],
      locationId: currentLocation.queryMap?.locationId,
      ...(queue?.filters?.inTriage && { inTriage: queue.filters?.inTriage }),
    },
  })

  // select the correct query returns based on the search param sent
  const options: MedicationSuggestionItem[] | undefined =
    debouncedSearchTerm.length >= 3 && data?.searchOtcMedications && data.searchOtcMedications.length > 0
      ? data.searchOtcMedications.map((searchResult: OrderMedicationSearchResult) => ({
          name: searchResult._id,
          count: searchResult.count,
        }))
      : undefined

  const medications = currentLocation?.queryMap?.otcMedications || []
  const skus = currentLocation?.queryMap?.skus || []

  return (
    <>
      <SearchAutoComplete
        withCapsule
        label={label}
        hotKey={isVisible ? hotkey : ''}
        isModal={isModal}
        placeholder="Search by name or SKU"
        iconColor={bodySecondaryColor}
        autoCompleteSuggestions={
          error
            ? [{ error: `Error searching Medications: ${JSON.stringify(error)}` }]
            : loading
            ? [{ loading }]
            : options ?? [{ noResults: true }]
        }
        onChange={setSearchTerm}
        value={searchTerm}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as MedicationSuggestionItem)} />}
        onSelect={chosen => {
          setSearchTerm('')
          if (!chosen) {
            return
          }
          // If the chosen name is all numbers, it's an SKU
          if (shouldNotUseSimplifiedMedicationSearch && !!chosen.name.match(/^\d+$/)) {
            if (!skus.includes(chosen.name))
              routeToMergedQuery({ skus: [...skus, chosen.name], ndcs: [], medications: [], page: 0 })
          } else {
            if (!medications.includes(chosen.name))
              routeToMergedQuery({ otcMedications: [...medications, chosen.name], ndcs: [], medications: [], page: 0 })
          }
        }}
      />
      <SearchResultLozengeList>
        {medications.map((name: string, idx: number) => {
          return (
            <SearchResultLozenge
              key={idx}
              closeCallback={() => {
                if (medications.includes(name)) {
                  const newMeds = [...medications]
                  newMeds.splice(medications.indexOf(name), 1)

                  routeToMergedQuery({ otcMedications: newMeds, page: 0 })
                }
              }}
            >
              <p>{name}</p>
            </SearchResultLozenge>
          )
        })}
        {shouldNotUseSimplifiedMedicationSearch &&
          skus.map((name: string, idx: number) => {
            return (
              <SearchResultLozenge
                key={idx}
                closeCallback={() => {
                  if (skus.includes(name)) {
                    const newSKUs = [...skus]
                    newSKUs.splice(skus.indexOf(name), 1)

                    routeToMergedQuery({ skus: newSKUs, page: 0 })
                  }
                }}
              >
                <p>{name}</p>
              </SearchResultLozenge>
            )
          })}
      </SearchResultLozengeList>
    </>
  )
}

export default OrderOTCSearch
