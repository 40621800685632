import AddressEntry from 'components/AddressEntry'
import {
  StyledRXImageCell,
  RXListRowSingle,
  ListRowLabel,
  ListRowValue,
  SmallPaddingBlock,
  RaisedAnchor,
  ImageFilledHeader,
} from 'components/RXTable'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import type { Prescription, Prescriber } from 'types'
import { checkIfProvided, formatPhoneNumber, getPrescriberAddressWithFax } from 'utils'
import { normalizeProviderType } from 'utils/prescriber'

type ReviewPrescriberProps = {
  prescription?: Prescription
}

const ReviewPrescriber = ({ prescription }: ReviewPrescriberProps): JSX.Element => {
  const { getCustomerNameById } = useTPCacheContext()

  if (!prescription) {
    return <></>
  }

  const rxPrescriber = prescription.prescriber as Prescriber
  const address = getPrescriberAddressWithFax(prescription)
  return (
    <>
      <TitleRow>
        <SmallPaddingBlock />
        <RXImageInsuranceFilledHeader>
          <RaisedAnchor id={`ReviewPrescriber`} />
          Prescriber
        </RXImageInsuranceFilledHeader>
      </TitleRow>

      <RXListRowSingle>
        <StyledRXImageCell closeOffTopBorder>
          <ListRowLabel>Prescriber:</ListRowLabel>
          <ListRowValue>
            {rxPrescriber.firstName} {rxPrescriber.lastName}
            {rxPrescriber.suffix ? `, ${rxPrescriber.suffix}` : ''}
          </ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>Credential:</ListRowLabel>
          <ListRowValue>
            {rxPrescriber.npiDoc?.credential ? normalizeProviderType(rxPrescriber.npiDoc.credential) : 'unknown'}
          </ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>NPI:</ListRowLabel>
          <ListRowValue>{checkIfProvided(rxPrescriber.npi)}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>Supervising prescriber:</ListRowLabel>
          <ListRowValue>
            {rxPrescriber.firstName} {rxPrescriber.lastName}
          </ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>Address:</ListRowLabel>
          <AddressEntry address={address} />
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>Phone:</ListRowLabel>
          <ListRowValue>{checkIfProvided(formatPhoneNumber(address?.phone))}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>Fax:</ListRowLabel>
          <ListRowValue>{checkIfProvided(formatPhoneNumber(address?.fax))}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell>
          <ListRowLabel>DEA:</ListRowLabel>
          <ListRowValue>{checkIfProvided(rxPrescriber.dea)}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <StyledRXImageCell closeOffBottomBorder>
          <ListRowLabel>Customer:</ListRowLabel>
          <ListRowValue>{checkIfProvided(getCustomerNameById(prescription.customerId))}</ListRowValue>
        </StyledRXImageCell>
      </RXListRowSingle>
      <SmallPaddingBlock />
    </>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const TitleRow = styled.div``

export default ReviewPrescriber
