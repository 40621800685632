import { useState, useEffect } from 'react'
import { CopayStatus, AutobotType, autobotMap, LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as CheckIcon } from 'assets/icons/circlecheck.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { ReactComponent as OtcOnlyIcon } from 'assets/icons/otcOnly.svg'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import ActionButton from 'components/ActionButton'
import { AppliedSearchFilters } from 'components/AppliedSearchFilters'
import CustomerSelectorDropDown from 'components/CustomerSelectorDropDown'
import IconWrapper from 'components/IconWrapper'
import LocationSelectorDropDown from 'components/LocationSelectorDropDown'
import OrderSearch from 'components/OrderSearch'
import { ActionBar, PageContainer } from 'components/PageStructure'
import { PriorAuthorizationSearch } from 'components/PriorAuthorization'
import StatesSelect from 'components/StatesSelect'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useOrderLock from 'hooks/useOrderLock'
import useQueryParamBoolean from 'hooks/useQueryParamBoolean'
import useSetPageTitle from 'hooks/useSetPageTitle'
import ActionButtons from 'pages/fulfillment/ActionButtons'
import QueueSelectorDropdown from 'pages/fulfillment/components/QueueSelectorDropdown'
import FilterSection from 'pages/fulfillment/queues/sections/FilterSection'
import QueuesSection from 'pages/fulfillment/queues/sections/QueuesSection'
import useReleaseNotesModal from 'pages/fulfillment/queues/useReleaseNotes'
import { useFlag } from 'providers/LaunchDarklyProvider'
import SelectionProvider from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import {
  bodyPrimaryColor,
  borderColor,
  primaryActiveButtonBackground,
  primaryBackgroundColor,
  primaryColor,
} from 'styles/styleVariables'
import type { OrderSearchVariables } from 'types'

const Queues = (): JSX.Element => {
  const {
    currentLocation: { queryMap },
    routeToMergedQuery,
    QueryToolkit: { StringBoolean },
  } = usePlusClient()

  const [[hideFilterControls, isFirstLoad], setHideFilterControls] = useState([true, true])
  const [otcOnlyControl] = useQueryParamBoolean('otcOnly', false)
  const [rxOtcOnlyControl] = useQueryParamBoolean('rxOtcOnly', false)
  const [orderSearchVariables, setOrderSearchVariables] = useState<OrderSearchVariables>({
    isOtcOnly: otcOnlyControl,
    isRxOtcOnly: rxOtcOnlyControl,
  })
  const [priorAuthorizationStringSearch, setPriorAuthorizationStringSearch] = useState<string>('')
  const fulfillmentQueue = useFulfillmentQueue()
  const orderId = localStorage.getItem(`orderId`) ?? undefined
  const { clearOrderLock } = useOrderLock(orderId)

  useReleaseNotesModal()

  const toggleHideFilterControls = () => setHideFilterControls([!hideFilterControls, isFirstLoad])

  useSetPageTitle(fulfillmentQueue ? 'Fulfillment - ' + fulfillmentQueue.name : 'Fulfillment Summary')

  const hasNoPrints = StringBoolean(queryMap.hasNoPrints)
  const hasPickSlipPrints = StringBoolean(queryMap.hasPickSlipPrints)
  const completeAutomation = StringBoolean(queryMap.completeAutomation)

  const currentAutobot: AutobotType = queryMap.autobot || AutobotType.mini
  const autobotShowPrint = currentAutobot === AutobotType.noStatus ? false : autobotMap[currentAutobot].showPrint
  const autobotShowCompleteFilter = currentAutobot !== AutobotType.noStatus

  const isCopay = fulfillmentQueue && fulfillmentQueue.name === FulfillmentQueueName.Copay
  const isPriorAuthorizationOrder =
    fulfillmentQueue && fulfillmentQueue.name === FulfillmentQueueName.PriorAuthorizationNew
  const isPriorAuthorizationCopay = isCopay && queryMap.copayStatus === CopayStatus.PriorAuthorization
  const isPriorAuthorization = isPriorAuthorizationOrder || isPriorAuthorizationCopay

  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false

  useEffect(() => {
    if (orderId) {
      clearOrderLock()
      localStorage.removeItem('orderId')
    }
  }, [])

  useEffect(() => {
    setOrderSearchVariables({
      isOtcOnly: otcOnlyControl,
      isRxOtcOnly: rxOtcOnlyControl,
    })
    // Disable hasPickSlipPrints filter when there is no otc filter applied
    if (hasPickSlipPrints !== undefined && !otcOnlyControl && !rxOtcOnlyControl) {
      routeToMergedQuery({ hasPickSlipPrints: undefined })
    }
  }, [otcOnlyControl, rxOtcOnlyControl, hasPickSlipPrints])

  return (
    <SelectionProvider>
      <QueuesPageContainer>
        <Title>Fulfillment</Title>
        <ActionBar>
          <FilterControls>
            {!displayDashboardDesignV2 && <QueueSelectorDropdown queue={fulfillmentQueue?.name} />}
            <LocationSelectorDropDown />
            {fulfillmentQueue && (
              <StyledActionButton
                active={!hideFilterControls}
                onClick={toggleHideFilterControls}
                label="Filters"
                hotKey="f"
                icon={
                  <>
                    <IconWrapper>
                      <FilterIcon fill={!hideFilterControls ? 'white' : 'black'} />
                    </IconWrapper>
                  </>
                }
              />
            )}
            {fulfillmentQueue?.name === FulfillmentQueueName.Packing && (
              <StyledActionButton
                data-testid="filter-otc-only"
                aria-pressed={otcOnlyControl}
                active={otcOnlyControl}
                onClick={() => routeToMergedQuery({ otcOnly: !otcOnlyControl, rxOtcOnly: false })}
                label="OTC only"
                icon={
                  <>
                    <IconWrapper>
                      <OtcOnlyIcon fill={otcOnlyControl ? 'white' : 'black'} />
                    </IconWrapper>
                  </>
                }
              />
            )}
            {fulfillmentQueue?.name === FulfillmentQueueName.Packing && (
              <StyledActionButton
                data-testid="filter-otc-only"
                aria-pressed={rxOtcOnlyControl}
                active={rxOtcOnlyControl}
                onClick={() => routeToMergedQuery({ rxOtcOnly: !rxOtcOnlyControl, otcOnly: false })}
                label="RX + OTC"
                icon={
                  <>
                    <IconWrapper>
                      <OtcOnlyIcon fill={rxOtcOnlyControl ? 'white' : 'black'} />
                    </IconWrapper>
                  </>
                }
              />
            )}
            {!fulfillmentQueue && <CustomerSelectorDropDown />}
            {!fulfillmentQueue && (
              <SelectWrapper>
                <StatesSelect label="" variant="small" />
              </SelectWrapper>
            )}
            {(fulfillmentQueue?.name === FulfillmentQueueName.Fill ||
              (fulfillmentQueue?.name === FulfillmentQueueName.Automation && autobotShowPrint)) && (
              <StyledActionButton
                active={hasNoPrints}
                onClick={() => routeToMergedQuery({ hasNoPrints: hasNoPrints ? undefined : true })}
                label="Unprinted"
                icon={
                  <>
                    <IconWrapper>
                      <PrintIcon fill={hasNoPrints ? 'white' : 'black'} />
                    </IconWrapper>
                  </>
                }
                hotKey="u"
              />
            )}
            {fulfillmentQueue?.name === FulfillmentQueueName.Automation && autobotShowCompleteFilter && (
              <StyledActionButton
                active={completeAutomation}
                onClick={() => routeToMergedQuery({ completeAutomation: completeAutomation ? undefined : true })}
                label="Automation Complete"
                icon={
                  <>
                    <IconWrapper>
                      <CheckIcon fill={completeAutomation ? 'white' : 'black'} />
                    </IconWrapper>
                  </>
                }
                hotKey="u"
              />
            )}
            {fulfillmentQueue && !isPriorAuthorization && (
              <StyledOrderSearch searchTermChanged={setOrderSearchVariables} />
            )}
            {fulfillmentQueue && isPriorAuthorization && (
              <StyledPriorAuthorizationSearch onChange={setPriorAuthorizationStringSearch} />
            )}
          </FilterControls>
          <ActionButtons />
          {fulfillmentQueue && <AppliedSearchFilters orderSearchVariables={orderSearchVariables} />}
        </ActionBar>
        {fulfillmentQueue ? <FilterSection hideFilters={hideFilterControls} isFirstLoad={isFirstLoad} /> : <div />}
        <QueuesSection
          orderSearchVariables={orderSearchVariables}
          priorAuthorizationStringSearch={priorAuthorizationStringSearch}
        />
      </QueuesPageContainer>
    </SelectionProvider>
  )
}

const Title = styled.h1`
  font-size: 24px;
  grid-row: title;
  margin-top: 1.25rem;
  grid-column: 1 / span 2;
  margin-bottom: 0.8rem;
  padding-left: 1.875rem;
`

const FilterControls = styled.div`
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > button:nth-child(n + 2) {
    margin-left: 0.625rem;
  }
  > div:nth-child(n + 2) {
    margin-left: 0.625rem;
  }
`
const SelectWrapper = styled.div`
  height: 3.75rem;
  width: 10rem;
`

const StyledOrderSearch = styled(OrderSearch)`
  height: 2.25rem;
  margin-left: 0.625rem;
  input {
    margin-left: 0rem;
  }
`

const StyledPriorAuthorizationSearch = styled(PriorAuthorizationSearch)`
  height: 2.25rem;
  margin-left: 0.625rem;
  input {
    margin-left: 0rem;
  }
`

const Inactive = css`
  background-color: ${primaryBackgroundColor};
  color: ${bodyPrimaryColor};
  > svg {
    fill: ${bodyPrimaryColor};
  }
`

const Active = css`
  background-color: ${primaryActiveButtonBackground};
  color: ${primaryBackgroundColor};
  > svg {
    fill: ${primaryBackgroundColor};
  }
`

export const StyledActionButton = styled(ActionButton)<{ active?: boolean }>`
  font-weight: 500;
  font-family: Roboto;
  font-size: 1rem;
  ${({ active }) => (active ? Active : Inactive)}
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5rem;
  border: 0.125rem solid;
  border-color: ${borderColor};
  border-radius: 0.25rem;
  min-width: 4rem;
  padding: 0.3rem 1rem;
  :hover {
    border: 2px solid ${primaryColor};
  }
  :focus {
    border: 0.1px solid ${primaryColor};
  }
`

const QueuesPageContainer = styled(PageContainer)`
  background-color: white;
  display: grid;
  grid-template-rows: auto;
  grid-template-rows: [title] auto [actionBar] auto [content] 1fr;
  grid-template-columns: [filter] auto [fills] 1fr;
`

export default Queues
