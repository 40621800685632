import { useCallback, useRef } from 'react'
import { RxFillRequestStatus } from '@truepill/tpos-types'
import { GET_NEXT_PV1 } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useInfoToast from 'hooks/toast/useInfoToast'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import { usePlusClient } from 'providers/VisionRouter'
import { PV1Events } from 'services/analytics/events/pv1Events'
import type { Fill, Order, FillInfoType } from 'types'
import { getNextRxFillRequest, nextOrderFillWithStatus } from 'utils'
import useAnalytics from './useAnalytics'
import { FulfillmentQueueName } from './useFulfillmentQueue'

let lastFilterSet = {}

type useGetNextPV1Type = {
  startPV1Work: () => void | Promise<void>
  getNextPV1: (currentOrder?: Order, currentFill?: Fill) => void | Promise<void>
}

const useGetNextPV1 = (): useGetNextPV1Type => {
  const {
    client,
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()
  const { trackPV1Event } = useAnalytics()
  const showErrorToast = useErrorToast()
  const showInfoToast = useInfoToast()
  const showInfoToastRef = useAutoUpdatingRef(showInfoToast)
  const showErrorToastRef = useAutoUpdatingRef(showErrorToast)
  const streak = useRef(0)

  const getNextPV1 = useCallback(
    (currentOrder?: Order, currentFill?: Fill) => {
      // Check for any remaining PV1 fills if the order is a batch
      //
      // This only gets called for the second fill of a batch order
      if (currentFill && currentOrder) {
        const { nextFill } = getNextRxFillRequest(currentOrder, currentFill, 'PV1')
        if (nextFill) {
          trackPV1Event(PV1Events.PV1_START.key, { fillId: nextFill.fillId })
          streak.current += 1
          showInfoToastRef.current(`Another order is ready for your review. (Streak: ${streak.current})`)
          return routeTo
            .fulfillment(currentOrder._id, nextFill.fill._id, FulfillmentQueueName.PV1, {
              searchMap: {
                ...lastFilterSet,
              },
            })
            .now()
        }
      }

      return (async () => {
        try {
          const variables = Object.keys(lastFilterSet).length
            ? lastFilterSet
            : {
                selectedLocationIds: queryMap.locationIds || undefined,
                medicationNames: queryMap.medications || undefined,
                customerIds: queryMap.customerIds || undefined,
                batchOrders: queryMap.batchOrders !== undefined ? queryMap.batchOrders === 'true' : undefined,
                multiPetOrders: queryMap.multiPetOrders !== undefined ? queryMap.multiPetOrders === 'true' : undefined,
                locationId: queryMap.locationId || tokenContext?.locationId,
                paymentType: queryMap.paymentType || undefined,
                specialHandlingTags: queryMap.noHandlingTags ? [] : queryMap.specialHandlingTags || undefined,
                states: queryMap.noStates ? [] : queryMap.states || undefined,
              }

          // using client.query here for the simple reason that useLazyQuery and
          // useQuery hooks ignore fetchPolicy settings sometimes. It's a known
          // bug but there's not been any traction on it in the last calendar
          // year. It also means the consequences of the queries are handled where
          // the query happens instead of needing to rely on an useEffect to get
          // the job done.
          const { data } = await client.query({
            query: GET_NEXT_PV1,
            fetchPolicy: 'no-cache',
            variables,
          })

          const order = data.getNextPV1 as Order
          // We know this endpoint only returns orders with valid fill statuses
          const nextFillInfoData = nextOrderFillWithStatus(order, RxFillRequestStatus.PV1) as FillInfoType
          streak.current += 1

          trackPV1Event(PV1Events.PV1_START.key, { fillId: nextFillInfoData?.fillId })
          if (nextFillInfoData) {
            const { orderId, fillId } = nextFillInfoData
            showInfoToastRef.current(`Another order is ready for your review. (Streak: ${streak.current})`)
            routeTo
              .fulfillment(orderId, fillId, FulfillmentQueueName.PV1, {
                searchMap: {
                  ...lastFilterSet,
                },
              })
              .now()
          } else {
            showInfoToastRef.current('No more work found')
            routeTo
              .fulfillmentQueue(FulfillmentQueueName.PV1, {
                searchMap: {
                  locationId: tokenContext?.locationId,
                  paymentType: queryMap.paymentType || undefined,
                },
              })
              .now()
          }
        } catch (error) {
          showErrorToastRef.current('Failed to get next PV1: ' + error.message)
        }
      })()
    },
    [showErrorToastRef, showInfoToastRef, queryMap, tokenContext.locationId, client, routeTo],
  )

  const startPV1Work = () => {
    lastFilterSet = {
      selectedLocationIds: queryMap.locationIds || undefined,
      medicationNames: queryMap.medications || undefined,
      customerIds: queryMap.customerIds || undefined,
      batchOrders: queryMap.batchOrders !== undefined ? queryMap.batchOrders === 'true' : undefined,
      multiPetOrders: queryMap.multiPetOrders !== undefined ? queryMap.multiPetOrders === 'true' : undefined,
      locationId: queryMap.locationId || tokenContext?.locationId,
      paymentType: queryMap.paymentType || undefined,
      specialHandlingTags: queryMap.noHandlingTags ? [] : queryMap.specialHandlingTags || undefined,
      states: queryMap.noStates ? [] : queryMap.states || undefined,
    }
    return getNextPV1()
  }

  return { startPV1Work, getNextPV1 }
}

export default useGetNextPV1
