import { useQuery } from '@truepill/tpos-react-router'
import { PriorAuthorizationStatus, AutobotType, autobotMap, CopayStatus } from '@truepill/tpos-types'
import { LIST_AUTOBOTS } from 'gql'
import type { CountsByCopayStatus } from 'hooks/useCopayCountByStatus'
import useCopayCountByStatus from 'hooks/useCopayCountByStatus'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import type { CountsByAutomationStatus } from 'hooks/useOrdersCountByAutomationStatus'
import useOrdersCountByAutomationStatus from 'hooks/useOrdersCountByAutomationStatus'
import type { CountsByPriorAuthorizationStatus } from 'hooks/usePriorAuthorizationCountByStatus'
import usePriorAuthorizationCountByStatus from 'hooks/usePriorAuthorizationCountByStatus'
import TicketCounter from 'pages/fulfillment/components/TicketCounter'
import TruncatedUserList from 'pages/fulfillment/components/TruncatedUserList'
import { useSelectionContext } from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import { btnPrimary, alertRed } from 'styles/styleVariables'
import { FillWorkStatus } from 'types'
import type { Autobot, PriorAuthorization, QueueItem } from 'types'
import { isOrderItems } from 'utils'
import { isPriorAuthorizationItems } from 'utils/copayChecks'
import QueueSelectedCheckbox from './QueueSelectedCheckbox'

type QueueHeaderProps = {
  items: QueueItem[] | PriorAuthorization[]
  totalItems: number
  queue: FulfillmentQueue
  selected?: boolean
  locationId?: string
}

const useAutomationCounts = (
  queue: FulfillmentQueue,
  queryMap: Record<string, any>,
): CountsByAutomationStatus | undefined => {
  const automationOrdersCountVariables = {
    ...(!queryMap.locationIds && queryMap.locationId && { locationId: queryMap.locationId }),
    ...(queryMap.locationIds && { selectedLocationIds: queryMap.locationIds }),
    ...(queryMap.customerIds && { customerIds: queryMap.customerIds }),
    ...(queryMap.medications && { medicationNames: queryMap.medications }),
  }

  const { data } = useOrdersCountByAutomationStatus({
    params: automationOrdersCountVariables,
    skip: queue?.name !== FulfillmentQueueName.Automation,
  })

  return data?.countByStatus
}

const useCopayStatusCounts = (queryMap: Record<string, any>): CountsByCopayStatus | undefined => {
  const copayStatusCountVariables = {
    ...(!queryMap.locationIds && queryMap.locationId && { locationId: queryMap.locationId }),
    ...(queryMap.locationIds && { selectedLocationIds: queryMap.locationIds }),
    ...(queryMap.customerIds && { customerIds: queryMap.customerIds }),
    ...(queryMap.medications && { medicationNames: queryMap.medications }),
  }

  const { data } = useCopayCountByStatus({
    params: copayStatusCountVariables,
  })

  return data?.countByStatus
}

const usePriorAuthorizationStatusCounts = (
  queryMap: Record<string, any>,
  StringBoolean: (val: string) => boolean,
): CountsByPriorAuthorizationStatus | undefined => {
  const getPriorAuthorizationVariables = {
    ...(queryMap.manualPriorAuthorizations && { isManual: !!StringBoolean(queryMap.manualPriorAuthorizations) }),
    ...(!queryMap.locationIds && queryMap.locationId && { locationId: queryMap.locationId }),
    ...(queryMap.customerIds && { customerIds: queryMap.customerIds }),
  }

  const { data } = usePriorAuthorizationCountByStatus({
    params: getPriorAuthorizationVariables,
  })

  return data?.countByStatus
}

const PriorAuthorizationSubQueue = () => {
  const {
    routeToMergedQuery,
    currentLocation: { queryMap },
    QueryToolkit: { StringBoolean },
  } = usePlusClient()

  const countByPriorAuthorizationStatus = usePriorAuthorizationStatusCounts(queryMap, StringBoolean)

  const currentPaStatus: PriorAuthorizationStatus | 'All' =
    queryMap.paSubQueueStatus || PriorAuthorizationStatus.ManualStart

  return (
    <SubQueuesContainer>
      <SubQueueOption
        active={currentPaStatus === PriorAuthorizationStatus.ManualStart}
        onClick={() => routeToMergedQuery({ paSubQueueStatus: PriorAuthorizationStatus.ManualStart })}
      >
        {`Manual Start (${countByPriorAuthorizationStatus?.manualStart ?? '0'})`}
      </SubQueueOption>
      <SubQueueOption
        active={currentPaStatus === PriorAuthorizationStatus.Pending}
        onClick={() => routeToMergedQuery({ paSubQueueStatus: PriorAuthorizationStatus.Pending })}
      >
        {`Pending (${countByPriorAuthorizationStatus?.pending ?? '0'})`}
      </SubQueueOption>
      <SubQueueOption
        active={currentPaStatus === PriorAuthorizationStatus.ProviderNotified}
        onClick={() => routeToMergedQuery({ paSubQueueStatus: PriorAuthorizationStatus.ProviderNotified })}
      >
        {`Provider Notified (${countByPriorAuthorizationStatus?.providerNotified ?? '0'})`}
      </SubQueueOption>
      <SubQueueOption
        active={currentPaStatus === PriorAuthorizationStatus.PayerNotified}
        onClick={() => routeToMergedQuery({ paSubQueueStatus: PriorAuthorizationStatus.PayerNotified })}
      >
        {`Payer Notified (${countByPriorAuthorizationStatus?.payerNotified ?? '0'})`}
      </SubQueueOption>
      <SubQueueOption
        active={currentPaStatus === 'All'}
        onClick={() => routeToMergedQuery({ paSubQueueStatus: 'All' })}
      >
        {`All (${countByPriorAuthorizationStatus?.all ?? '0'})`}
      </SubQueueOption>
    </SubQueuesContainer>
  )
}

const getQueueName = (queueName: FulfillmentQueueName) => {
  if (queueName === FulfillmentQueueName.PriorAuthorizationNew) return 'Prior Authorization (ALL)'
  return queueName.toUpperCase()
}

const QueueHeader = ({ items, totalItems, queue, locationId }: QueueHeaderProps): JSX.Element => {
  const { initSelections } = useSelectionContext()
  const { data: autobotData } = useQuery(LIST_AUTOBOTS, {
    variables: { ...(locationId && { locationId }) },
  })

  const {
    currentLocation: { queryMap },
    routeToMergedQuery,
  } = usePlusClient()

  const automationCounts = useAutomationCounts(queue, queryMap)
  const currentAutobotType: AutobotType = queryMap.autobot || AutobotType.mini
  const countByCopayStatus = useCopayStatusCounts(queryMap)
  const currentCopayStatus: CopayStatus = queryMap.copayStatus || CopayStatus.Pending

  let queueLimit = 0
  let disabledSelectOnCompleteToggle = true

  if (currentAutobotType !== AutobotType.noStatus) {
    const modelName = autobotMap[currentAutobotType].modelName
    queueLimit = autobotData?.getAutobots
      .filter((i: Autobot) => i.modelName === modelName)
      .reduce((total: number, cur: any) => total + cur, 0)
    disabledSelectOnCompleteToggle = autobotMap[currentAutobotType].disabledSelectOnCompleteToggle
  }

  return (
    <QueueHeaderContainer>
      <StyledQueueHeader>
        <QueueHeaderLeft>
          {isOrderItems(items) && (
            <QueueSelectedCheckbox
              items={items}
              disabled={queryMap.completeAutomation && disabledSelectOnCompleteToggle}
            />
          )}
          <QueueName>{getQueueName(queue.name)}</QueueName>
          {queue.name === 'Automation' && locationId && queueLimit > 0 && (
            <AutomationQueueCount>{`(${totalItems}/${queueLimit})`}</AutomationQueueCount>
          )}
          {!isPriorAuthorizationItems(items) && <TicketCounter items={items} status={FillWorkStatus.normal} />}
          {!isPriorAuthorizationItems(items) && <TicketCounter items={items} status={FillWorkStatus.overdue} />}
          {!isPriorAuthorizationItems(items) && <TicketCounter items={items} status={FillWorkStatus.nearOverdue} />}
        </QueueHeaderLeft>
        <QueueHeaderRight>{!isPriorAuthorizationItems(items) && <TruncatedUserList items={items} />}</QueueHeaderRight>
      </StyledQueueHeader>
      {queue.name === FulfillmentQueueName.Automation && (
        <SubQueuesContainer>
          <SubQueueOption
            active={currentAutobotType === AutobotType.deferredAutomation}
            onClick={() => {
              routeToMergedQuery({ autobot: AutobotType.deferredAutomation })
              initSelections([])
            }}
          >
            {`User-Initiated Batches (${automationCounts?.deferred ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            active={currentAutobotType === AutobotType.mini}
            onClick={() => {
              routeToMergedQuery({ autobot: AutobotType.mini })
              initSelections([])
            }}
          >
            {`Mini (${automationCounts?.mini ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            active={currentAutobotType === AutobotType.max}
            onClick={() => {
              routeToMergedQuery({ autobot: AutobotType.max })
              initSelections([])
            }}
          >
            {`Max (${automationCounts?.max ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            alert={(automationCounts?.unassigned ?? 0) > 0}
            active={currentAutobotType === AutobotType.noStatus}
            onClick={() => {
              routeToMergedQuery({ autobot: AutobotType.noStatus })
              initSelections([])
            }}
          >
            {`No Status (${automationCounts?.unassigned ?? '0'})`}
          </SubQueueOption>
        </SubQueuesContainer>
      )}
      {queue.name === FulfillmentQueueName.PriorAuthorizationNew && <PriorAuthorizationSubQueue />}
      {queue.name === FulfillmentQueueName.Copay && (
        <SubQueuesContainer>
          <SubQueueOption
            active={currentCopayStatus === CopayStatus.Pending}
            onClick={() => routeToMergedQuery({ copayStatus: CopayStatus.Pending })}
          >
            {`Pending (${countByCopayStatus?.pending ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            active={currentCopayStatus === CopayStatus.Triage}
            onClick={() => routeToMergedQuery({ copayStatus: CopayStatus.Triage })}
          >
            {`Triage (${countByCopayStatus?.triage ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            active={currentCopayStatus === CopayStatus.PriorAuthorization}
            onClick={() => routeToMergedQuery({ copayStatus: CopayStatus.PriorAuthorization })}
          >
            {`Prior Authorization (${countByCopayStatus?.priorAuthorization ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            active={currentCopayStatus === CopayStatus.Rejected}
            onClick={() => routeToMergedQuery({ copayStatus: CopayStatus.Rejected })}
          >
            {`Rejected (${countByCopayStatus?.rejected ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            active={currentCopayStatus === CopayStatus.Complete}
            onClick={() => routeToMergedQuery({ copayStatus: CopayStatus.Complete })}
          >
            {`Complete (${countByCopayStatus?.complete ?? '0'})`}
          </SubQueueOption>
          <SubQueueOption
            active={currentCopayStatus === CopayStatus.Confirmed}
            onClick={() => routeToMergedQuery({ copayStatus: CopayStatus.Confirmed })}
          >
            {`Confirmed (${countByCopayStatus?.confirmed ?? '0'})`}
          </SubQueueOption>
        </SubQueuesContainer>
      )}
    </QueueHeaderContainer>
  )
}

export default QueueHeader

const QueueHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`
const StyledQueueHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
  margin-bottom: 0.625rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  width: 100%;
`

const SubQueuesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 100%;

  border-style: solid;
  border-bottom-width: 1px;
  border-color: #e9ecf0;
`

const QueueHeaderEntry = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  > span {
    font-size: 0.6em;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
`

const QueueHeaderLeft = styled.div`
  > label {
    margin-right: 0.5rem;
    margin-left: 1.25rem;
  }
  ${QueueHeaderEntry}
`

const QueueHeaderRight = styled.div`
  ${QueueHeaderEntry}
  justify-content: flex-end;
`

const QueueName = styled.h3`
  cursor: pointer;
  font-weight: 500;
  font-size: 1em;
  margin-right: 0.1rem;
`

const AutomationQueueCount = styled.h3`
  font-size: 1em;
  margin-left: 0.1rem;
  cursor: pointer;
`

const SubQueueOption = styled.button<{ active?: boolean; alert?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  font-family: 'Lato';
  font-style: normal;
  height: 38px;

  border-style: solid;
  ${({ alert }) => (alert ? `border-color: ${alertRed};` : `border-color: ${btnPrimary};`)}
  ${({ active }) => `border-width: 0px 0px ${active ? 2 : 0}px 0px;`}

  background: white;
  ${({ active, alert }) => (alert ? `color: ${alertRed};` : active ? `color: ${btnPrimary};` : '')}

  line-height: 20px;
  margin-left: 8px;
  margin-right: 8px;
  min-width: 56px;
`
