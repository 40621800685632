import { LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as AlertTriangle } from 'assets/icons/alert-triangle.svg'
import { useFlag } from 'providers/LaunchDarklyProvider'
import styled from 'styled-components'
import { capsuleRed } from 'styles/styleVariables'
import { type Prescription } from 'types'
import { isRxFirstFill } from 'utils/rxFields'

const FirstFillHighlight = ({ prescription }: { prescription: Prescription }) => {
  const shouldHighlightFirstFill = useFlag(LaunchDarkly.FeatureFlags.TEMP_HIGHLIGHT_FIRST_FILL)

  const isFirstFill = isRxFirstFill(prescription)

  if (!shouldHighlightFirstFill || !isFirstFill) return null

  return (
    <FirstFillHighlightWrapper>
      <div>
        <AlertTriangle />
        First Fill
        <AlertTriangle />
      </div>
    </FirstFillHighlightWrapper>
  )
}

const FirstFillHighlightWrapper = styled.div`
  padding: 0 1.875rem;
  & > div {
    background-color: ${capsuleRed};
    color: white;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.5rem 0;
    border-radius: 6px;
  }

  & svg {
    width: 28px;
    height: 28px;
    path {
      stroke: white;
      stroke-width: 1px;
    }
  }
`

export default FirstFillHighlight
