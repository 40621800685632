import { Chip, Tooltip, Text } from '@truepill/react-capsule'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { accentPurple } from 'styles/styleVariables'

type InventoryShelfLocationsTagProps = {
  useLozenge?: boolean
  warehouseLocation?: string
}

const InventoryShelfLocationsTag = (props: InventoryShelfLocationsTagProps): JSX.Element => {
  const { useLozenge, warehouseLocation } = props

  // tooltip has zindex set to prevent it from rendering below other assets
  const renderTag = ({ label, content }: { label: string; content: string }) => (
    <Tooltip
      label={label}
      css={{ backgroundColor: accentPurple, color: 'white', zIndex: 10 }}
      arrowCss={{ borderColor: accentPurple, zIndex: 10 }}
    >
      {useLozenge && <StyledLozenge>{content}</StyledLozenge>}
      {!useLozenge && (
        <Chip css={{ backgroundColor: accentPurple, color: 'white', whiteSpace: 'nowrap' }}>
          <Text variant="body">{content}</Text>
        </Chip>
      )}
    </Tooltip>
  )

  if (!warehouseLocation) {
    return renderTag({
      label: `No Location for this fill`,
      content: 'No Location',
    })
  }

  return renderTag({
    label: `Location for this fill is ${warehouseLocation}`,
    content: warehouseLocation,
  })
}

export default InventoryShelfLocationsTag

const StyledLozenge = styled(Lozenge)`
  background-color: ${accentPurple};
  color: white;
`
